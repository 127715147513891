import { FC, useEffect, useState } from 'react';
import {
  AlertMessage,
  AvatarComponent,
  Flex,
  MenuItem,
  PrimaryButton,
  Text,
} from '../../../components';
import { Flex as AntFlex, Dropdown, Menu, Spin, TableColumnsType } from 'antd';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { UserI } from '../../../types/settings.types';
import { CirclePlus, DisabledPlusCircle, MenuThreeDots } from '../../../assets';
import TableComponent from '../../../components/table/TableComponent';
import NameTag from '../../../components/tags/NameTag';
import { getPascalCaseUtil } from '../../../utils/caseUtil';
import SettingsLayout from '../settingsLayout/SettingsLayout';
import AddEditUserModal from './AddEditUserModal';
import {
  useArchiveAdmin,
  useCreateAdmin,
  useGetAdmins,
  useUpdateAdmin,
} from '../../../api/userHooks';
import {
  getCreatableRoles,
  getEditableDeletableRoles,
  isBusinessOwner,
} from '../../../utils/userRoleUtil';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { UserMeResponseI, UserRoleIdEnum } from '../../../types/user.types';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/auth-context';
import { Message } from '../../../components/message/Message';
import { addDotsForLongText } from '../../../utils/textUtil';

const FlexComp = styled(AntFlex)`
  border-bottom: 1px solid var(--Button-Fill, ${theme.gray400});
  padding: 24px 24px 16px 24px;
`;

const HeadingText = styled(Text)`
  @media screen and (max-width: 768px) {
    display: none !important;
  }
`;

const sortDataSource = (admins: UserI[], me: UserMeResponseI): UserI[] => {
  let sortedData: UserI[] = [];
  if (admins && me) {
    sortedData = [...admins].sort((a, b) =>
      a.id === me.id ? -1 : b.id === me.id ? 1 : 0,
    );
  }
  return sortedData;
};

const Users: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [rowData, setRowData] = useState<UserI>();
  const [creatableRoles, setCreatableRoles] = useState<number[]>([]);
  const [editableDeletableRoles, setEditableDeletableRoles] = useState<
    number[]
  >([]);
  const [sortedDataSource, setSortedDataSource] = useState<UserI[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const { currentUser, isLoading } = useAuth();
  const {
    data: admins,
    isLoading: isLoadingAdminData,
    refetch: getAdmins,
  } = useGetAdmins();
  const {
    mutate: createAdmin,
    data: createdAdminData,
    error: createAdminError,
    isLoading: isLoadingCreateAdmin,
    isSuccess: isCreateAdminSuccess,
  } = useCreateAdmin();
  const {
    mutate: updateAdmin,
    data: updatedAdmin,
    error: updateAdminError,
    isSuccess: isUpdateAdminSuccess,
  } = useUpdateAdmin();
  const {
    mutate: archiveAdmin,
    data: archivedAdmin,
    error: archiveAdminError,
    isSuccess: isArchiveAdminSuccess,
  } = useArchiveAdmin();

  useEffect(() => {
    if (currentUser) {
      const creatableRoles = getCreatableRoles(currentUser.role.id);
      setCreatableRoles(creatableRoles);
      const editableDeletableRoles = getEditableDeletableRoles(
        currentUser.role.id,
      );
      setEditableDeletableRoles(editableDeletableRoles);

      if (currentUser.role.id === UserRoleIdEnum.SUPER_ADMIN_MEMBER) {
        navigate('/settings/profile');
      }
    }
  }, [currentUser]);

  useEffect(() => {
    getAdmins();
  }, [createdAdminData, updatedAdmin, archivedAdmin]);

  useEffect(() => {
    if (createAdminError) Message.error(createAdminError.msg);
  }, [createAdminError]);

  useEffect(() => {
    if (updateAdminError) Message.error(updateAdminError.msg);
  }, [updateAdminError]);

  useEffect(() => {
    if (archiveAdminError) Message.error(archiveAdminError.msg);
  }, [archiveAdminError]);

  useEffect(() => {
    if (isCreateAdminSuccess) Message.success('User created successfully!');
    setVisible(false);
  }, [isCreateAdminSuccess]);

  useEffect(() => {
    if (isArchiveAdminSuccess) Message.success('User removed successfully!');
    setVisible(false);
  }, [isArchiveAdminSuccess]);

  useEffect(() => {
    if (isUpdateAdminSuccess) Message.success('User updated successfully!');
    setVisible(false);
  }, [isUpdateAdminSuccess]);

  useEffect(() => {
    if (admins && currentUser) {
      let sortedAdminData = sortDataSource(admins, currentUser);
      if (!isBusinessOwner(currentUser.role.id) && !currentUser.isLabelAdmin) {
        sortedAdminData = sortedAdminData.filter(
          (admin) => !admin.isLabelAdmin,
        );
      }
      setSortedDataSource(sortedAdminData);
    }
  }, [admins, currentUser]);

  const hideThreeDots = (data: UserI): boolean => {
    if (data.id === currentUser?.id) {
      return true;
    } else if (!editableDeletableRoles.includes(data.role.id)) {
      return true;
    } else {
      return false;
    }
  };

  const columns: TableColumnsType<UserI> = [
    {
      title: <Text type={'footnote-regular'}>Name</Text>,
      dataIndex: 'name',
      width: 250,
      render: (value, data) => (
        <Flex display="flex">
          <AvatarComponent title={data.firstName} src={data.profilePictures} />
          <Text type="subheading-regular" color={theme.black} ml="6px" mt="6px">
            {addDotsForLongText(data.firstName, 18) +
              ' ' +
              addDotsForLongText(data.lastName, 18)}
          </Text>
        </Flex>
      ),
    },
    {
      title: <Text type={'footnote-regular'}>Role</Text>,
      dataIndex: 'role',
      width: 200,
      render: (value, data) => (
        <NameTag
          tagColor={
            isBusinessOwner(data.role.id)
              ? theme.brandTeal20
              : theme.brandDarkTeal
          }
          textColor={isBusinessOwner(data.role.id) ? theme.black : theme.white}
          text={getPascalCaseUtil(data.role.role)}
        />
      ),
    },
    {
      title: <Text type={'footnote-regular'}>Email</Text>,
      dataIndex: 'email',
      width: 250,
      render: (value, data) => (
        <Text type="subheading-regular" color={theme.black}>
          {addDotsForLongText(data?.email, 18)}
        </Text>
      ),
    },
    {
      title: <Text type={'footnote-regular'}>Contact Number</Text>,
      width: 200,
      dataIndex: 'contactNumber',
      render: (value, data) => (
        <Text type="subheading-regular" color={theme.black}>
          {data?.phoneNumber}
        </Text>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      width: 100,
      render: (_value, data) =>
        hideThreeDots(data) ? null : (
          <Dropdown
            dropdownRender={() => (
              <Menu>
                {currentUser?.role.id !== UserRoleIdEnum.ADMIN && (
                  <MenuItem
                    disabled={!editableDeletableRoles.includes(data.role.id)}
                    key="edit"
                    onClick={() => {
                      setVisible(true);
                      setRowData(data);
                    }}>
                    Edit
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setShowDeleteModal(true);
                    setRowData(data);
                  }}
                  disabled={!editableDeletableRoles.includes(data.role.id)}
                  key="archive">
                  <Text color={theme.red} type="subheading-regular">
                    Remove
                  </Text>
                </MenuItem>
              </Menu>
            )}>
            <img src={MenuThreeDots} />
          </Dropdown>
        ),
    },
  ];

  return (
    <>
      <SettingsLayout>
        <FlexComp>
          <HeadingText type="body-bold">Users</HeadingText>

          <Flex marginLeft="auto">
            <PrimaryButton
              disabled={
                currentUser &&
                isBusinessOwner(currentUser.role.id) &&
                currentUser.organization.userLimit <= sortedDataSource.length
                  ? true
                  : false
              }
              icon={
                currentUser &&
                isBusinessOwner(currentUser.role.id) &&
                currentUser.organization.userLimit <=
                  sortedDataSource.length ? (
                  <DisabledPlusCircle />
                ) : (
                  <CirclePlus />
                )
              }
              size="middle"
              onClick={() => {
                setRowData(undefined);
                setVisible(true);
              }}>
              Add User
            </PrimaryButton>
          </Flex>
        </FlexComp>
        {currentUser &&
          isBusinessOwner(currentUser.role.id) &&
          currentUser.organization.userLimit <= 1 && (
            <Flex m="16px 8px">
              <AlertMessage
                type="error"
                text="Oops! You've reached the maximum user limit. Please consider
            upgrading your plan to add more users. Please note you can only
            upgrade your plan using the mobile app."
              />
            </Flex>
          )}
        <Spin
          size="large"
          spinning={isLoadingAdminData || isLoading || isLoadingCreateAdmin}>
          <TableComponent
            loading={isLoadingAdminData}
            columns={columns}
            dataSource={sortedDataSource}
            scroll={{
              y: window.innerHeight - 300,
            }}
            withBorders={true}
          />
        </Spin>
      </SettingsLayout>
      {visible && (
        <AddEditUserModal
          visible={visible}
          data={rowData}
          onCancel={() => setVisible(false)}
          onSubmit={(formValues: UserI) => {
            if (!rowData) {
              createAdmin(formValues);
            } else {
              updateAdmin(formValues);
            }
          }}
          loading={isLoadingCreateAdmin}
          rolesToCreate={creatableRoles}
        />
      )}
      {showDeleteModal && rowData && (
        <DeleteModal
          visible={showDeleteModal}
          onClickCancel={() => {
            if (rowData && rowData.id) {
              archiveAdmin(rowData.id.toString());
            }
          }}
          content={`Are you sure you would like to remove 
          ${getPascalCaseUtil(rowData.firstName)} ${getPascalCaseUtil(rowData.lastName)} from 
          the database? This action cannot be undone.`}
          title="Confirm Remove"
          handleCancel={() => {
            setShowDeleteModal(false);
            setRowData(undefined);
          }}
          buttonLabel="Remove"
        />
      )}
    </>
  );
};
export default Users;
