import { FC } from 'react';
import { RequestIcon, ScheduledIcon, TodoIcon } from '../../assets';
import { InboxType } from '../enums/Inbox.enum';
import styled from '@emotion/styled';
import { Image } from '../../components';

interface Props {
  type: InboxType;
  alwaysMargin?: boolean;
}

type styledIconType = {
  alwaysMargin?: boolean;
};

const StyledInboxIcon = styled(Image)<styledIconType>`
  margin-right: 8px;

  @media (max-width: 470px), (min-width: 768px) and (max-width: 1250px) {
    margin-right: ${(props) => (props.alwaysMargin ? '8px' : '0px')};
  }
`;

const InboxIcon: FC<Props> = ({ type, alwaysMargin }) => {
  switch (type) {
    case InboxType.REQUEST:
      return (
        <StyledInboxIcon
          src={RequestIcon}
          alt="Request Icon"
          alwaysMargin={alwaysMargin}
        />
      );
    case InboxType.SCHEDULED:
      return (
        <StyledInboxIcon
          src={ScheduledIcon}
          alt="Scheduled Icon"
          alwaysMargin={alwaysMargin}
        />
      );
    case InboxType.TODO:
      return (
        <StyledInboxIcon
          src={TodoIcon}
          alt="Todo Icon"
          alwaysMargin={alwaysMargin}
        />
      );
    default:
      return null;
  }
};

export default InboxIcon;
