import { ComponentProps, FC } from 'react';
import { Segmented, SegmentedProps } from 'antd';
import styled from '@emotion/styled';
import { SegmentedValue } from 'antd/es/segmented';
import theme from '../../theme';

type props = ComponentProps<typeof Segmented> & SegmentedProps;

const StyledSegmented = styled(Segmented)<props>`
  background-color: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 8px;
  font-size: 17px;
  color: ${theme.black};
  padding: 4px;

  .ant-segmented-item {
    transition: black 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 8px;

    &:first-child {
      margin-right: 5px;
    }
  }
  .ant-segmented-thumb {
    background-color: ${theme.brandTeal20};
    border-radius: 8px;
    color: ${theme.brandDarkTeal};
    font-weight: 590px;
    border: 1px solid ${theme.brandDarkTeal};
  }
  .ant-segmented-item-selected {
    background: ${theme.brandTeal20};
    border-radius: 8px;
    color: ${theme.brandDarkTeal};
    font-weight: 590px;
    border: 1px solid ${theme.brandDarkTeal};

    &:first-child {
      margin-right: 5px;
    }
  }
`;

export const SegmentedComp: FC<SegmentedProps<SegmentedValue>> = (props) => {
  const handleChange = (value: unknown) => {
    if (props.onChange) {
      props.onChange(value as SegmentedValue);
    }
  };

  return <StyledSegmented {...props} onChange={handleChange} />;
};
