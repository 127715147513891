import React, { useEffect, useState } from 'react';
import { Menu, Button, Dropdown } from 'antd';
import { DateFrequencyEnum } from '../../types/report.types';
import theme from '../../theme';
import styled from '@emotion/styled';
import { DropdownBlack } from '../../assets';
import { Image } from '../image/Image';
import { MenuInfo } from 'rc-menu/lib/interface';

export const MenuComp = styled(Menu)`
  background: ${theme.white};
  border: 1px solid ${theme.gray300};
  border-radius: 4px;
  padding: 0px !important;
  width: 150px;

  @media (max-width: 760px) {
    width: 100%;
  }
`;

export const MenuItem = styled.div`
  border-bottom: 1px solid ${theme.black};
  padding: 11px 14px;
  margin-bottom: 0px !important;
`;
export const MenuItemClear = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 14px;
`;
export const DropdownComp = styled.span`
  margin-left: 5px;
  background: ${theme.gray200};
  border-radius: 14px;
  padding: 0px 8px;
`;
export const DropdownButton = styled(Button)`
  margin: 0px 0px 0px 16px !important;
  display: flex;
  justify-content: space-between;
  height: 32px;
  width: 150px;
  padding: 4px 12px 6px 16px !important;
  text-align: start !important;
  border-radius: 8px;
  border: 0.5px solid ${theme.brandDarkTeal} !important;

  @media (max-width: 760px) {
    width: 100%;
    margin-left: 0px !important;
    margin-bottom: 8px !important;
  }

  &:hover {
    color: ${theme.brandDarkTeal} !important;
  }
  :focus {
    color: ${theme.black} !important;
    border-color: ${theme.brandDarkTeal} !important;
  }
`;
interface Props {
  dateFrequency: DateFrequencyEnum;
  setDateFrequency: (val: DateFrequencyEnum) => void;
}
const DateFrequencySelector: React.FC<Props> = ({
  dateFrequency,
  setDateFrequency,
}) => {
  const [selectedLabel, setSelectedLabel] = useState<string>();

  const handleMenuClick = (e: MenuInfo) =>
    setDateFrequency(e.key as DateFrequencyEnum);

  interface MenuItemWithLabel {
    key: DateFrequencyEnum;
    label: string;
  }
  const items: MenuItemWithLabel[] = [
    {
      key: DateFrequencyEnum.LAST30DAYS,
      label: 'Last 30 Days',
    },
    {
      key: DateFrequencyEnum.LAST14DAYS,
      label: 'Last 14 Days',
    },
    {
      key: DateFrequencyEnum.LAST7DAYS,
      label: 'Last 7 Days',
    },
  ];

  useEffect(() => {
    const label = items.find((val) => val?.key === dateFrequency);
    if (label) setSelectedLabel(label.label);
  }, [dateFrequency]);

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }}>
      <DropdownButton>
        <div>{selectedLabel ? selectedLabel : 'Select Date Frequency'} </div>
        <div>
          <Image src={DropdownBlack} />
        </div>
      </DropdownButton>
    </Dropdown>
  );
};

export default DateFrequencySelector;
