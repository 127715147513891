import { FC, useEffect, useState } from 'react';
import ModalComponent from '../../components/modals/ModalComponent';
import { Flex, FormContainer, Input, SelectComp } from '../../components';
import { Form, Select, Spin } from 'antd';
import DatePickerComp from '../../components/datePicker/DatepickerComp';
import CustomTextArea from '../../components/TextArea/CustomTextArea';
import { useGetVenues } from '../../api/venueHooks';
import {
  CreateTaskI,
  InboxAreaI,
  RequestI,
} from './interfaces/inbox.interface';
import { ItemsI } from '../../types/equipmentsItems.types';
import { useScheduleTask } from '../../api/requestHooks';
import dayjs, { Dayjs } from 'dayjs';

interface PropsI {
  visible: boolean;
  selectedRequest: RequestI;
  refetchRequests: () => void;
  onCancel: () => void;
}
const ScheduleModal: FC<PropsI> = ({
  visible,
  onCancel,
  selectedRequest,
  refetchRequests,
}) => {
  const [form] = Form.useForm();
  const {
    data: venues,
    refetch: getVenues,
    isLoading: isLoadingVenues,
  } = useGetVenues();
  const {
    mutate: scheduleTask,
    isSuccess: isSuccessSchedule,
    isLoading: isLoadingSchedule,
  } = useScheduleTask();
  const [areas, setAreas] = useState<InboxAreaI[]>();
  const [items, setItems] = useState<ItemsI[]>();
  const [venue, setVenue] = useState<string>();
  const [area, setArea] = useState<string>();

  useEffect(() => {
    getVenues();
  }, []);

  useEffect(() => {
    if (venues && venues.length > 0) {
      form.setFieldValue('venue', venues[0].id);
      setVenue(venues[0].id);

      if (selectedRequest) {
        const defaultArea = venues[0]?.areas.find(
          (areaItem) => areaItem.name === selectedRequest.area?.name,
        );
        if (selectedRequest.area) {
          if (defaultArea) {
            form.setFieldValue('area', defaultArea.id);
            setArea(defaultArea.id);
          }
        }
        if (defaultArea && selectedRequest.item) {
          const defaultItem = defaultArea.items.find((itemVal: ItemsI) => {
            if (selectedRequest.item?.name) {
              return itemVal.name?.en === selectedRequest?.item.name?.en;
            } else {
              return (
                itemVal.defaultItemId.name?.en ===
                selectedRequest?.item?.defaultItemId.name?.en
              );
            }
          });
          if (defaultItem) form.setFieldValue('item', defaultItem.id);
        }
        if (selectedRequest.title) {
          form.setFieldValue('title', selectedRequest.title);
        }
      }
    }
  }, [venues, selectedRequest]);

  useEffect(() => {
    if (venue) {
      const venueObj = venues?.find((venueItem) => venueItem.id === venue);
      if (venueObj && venueObj.areas.length > 0) {
        setAreas(venueObj.areas);
      }
    }
  }, [venue, venues]);

  useEffect(() => {
    if (area && areas) {
      const areaObj = areas.find((areaVal) => areaVal.id === area);
      const areasObj = areaObj?.items;
      if (areasObj) setItems(areaObj.items);
    }
  }, [area, areas]);

  const handleSubmit = () => {
    const data: CreateTaskI = {
      ...form.getFieldsValue(),
      request: selectedRequest?.id,
    };
    scheduleTask(data);
  };

  useEffect(() => {
    if (isSuccessSchedule) onCancel();
    refetchRequests();
  }, [isSuccessSchedule]);

  return (
    <ModalComponent
      onCancel={onCancel}
      onSubmit={() => form && form.submit()}
      loadingBtn={isLoadingSchedule}
      disabled={isLoadingSchedule}
      header={<div>Schedule</div>}
      hideCancel={false}
      primaryBtnLabel="Add"
      visible={visible}>
      <Spin spinning={isLoadingVenues}>
        <Flex width={'100%'}>
          <FormContainer
            layout="vertical"
            form={form}
            validateTrigger="onBlur"
            onFinish={() => handleSubmit()}>
            <Form.Item
              label="Venue *"
              name="venue"
              rules={[{ required: true, message: 'Select venue' }]}>
              <SelectComp
                onChange={(val) => setVenue(val as string)}
                defaultValue={venues ? venues[0].id : ''}>
                {venues?.map((venue) => (
                  <Select.Option key={venue.id} value={venue.id}>
                    {venue.name}
                  </Select.Option>
                ))}
              </SelectComp>
            </Form.Item>
            <Form.Item
              label="Area *"
              name="area"
              rules={[{ required: true, message: 'Select area' }]}>
              <SelectComp
                onChange={(val) => setArea(val as string)}
                disabled={!venue}>
                {areas?.map((area) => (
                  <Select.Option key={area.id} value={area.id}>
                    {area.name}
                  </Select.Option>
                ))}
              </SelectComp>
            </Form.Item>

            {area && (
              <Form.Item label="Item" name="item">
                <SelectComp disabled={!area}>
                  {items?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name ? item.name?.en : item.defaultItemId.name?.en}
                    </Select.Option>
                  ))}
                </SelectComp>
              </Form.Item>
            )}

            <Form.Item
              label="Task title *"
              name="title"
              rules={[{ required: true, message: 'Enter the task title' }]}>
              <Input placeholder="Enter the task title" />
            </Form.Item>

            <Form.Item
              label="Schedule Date *"
              name="scheduledDate"
              rules={[{ required: true, message: 'Enter the schedule date' }]}>
              <DatePickerComp
                disabledDate={(current: Dayjs) =>
                  current && current < dayjs().startOf('day')
                }
              />
            </Form.Item>

            <Form.Item
              label="Instructions *"
              name="instructions"
              rules={[{ required: true, message: 'Enter the instructions' }]}>
              <CustomTextArea />
            </Form.Item>
          </FormContainer>
        </Flex>
      </Spin>
    </ModalComponent>
  );
};

export default ScheduleModal;
