import { useMutation } from 'react-query';
import { ApiError } from './_types';
import { http } from './_apiService';
import { ReportData } from '../types/report.types';

interface TestGetReportInterfaceI {
  venueId: string;
  dateFrequency: string;
}

export const useGetReportData = () => {
  return useMutation<ReportData, ApiError, TestGetReportInterfaceI>(
    async (val) => {
      const response = await http.get(
        `/report/${val.venueId}/${val.dateFrequency}`,
      );
      return response.data;
    },
  );
};

export const useGeneratePdfReport = () => {
  return useMutation<{ url: string }, ApiError, TestGetReportInterfaceI>(
    async (val) => {
      const response = await http.get(
        `/report/pdf/${val.venueId}/${val.dateFrequency}`,
      );

      const { url } = response.data;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      return response.data;
    },
  );
};
