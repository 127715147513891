export enum InboxType {
  REQUEST = 'REQUEST',
  TODO = 'TODO',
  SCHEDULED = 'SCHEDULED',
}

export enum InboxStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  SNOOZED = 'SNOOZED',
  SCHEDULED = 'SCHEDULED',
  OVERDUE = 'OVERDUE',
}
