import { FC } from 'react';
import NameTag from '../../components/tags/NameTag';
import { InboxStatus } from '../enums/Inbox.enum';
import theme from '../../theme';
import { capitalizeFirstLetter } from '../../utils/textUtil';

interface PropsI {
  status: InboxStatus;
}
const InboxStatusTag: FC<PropsI> = ({ status }) => {
  switch (status) {
    case InboxStatus.NEW:
      return (
        <NameTag
          isClickable={false}
          textColor={theme.white}
          tagColor={theme.blue}
          text={capitalizeFirstLetter(InboxStatus.NEW)}
        />
      );
    case InboxStatus.COMPLETED:
      return (
        <NameTag
          isClickable={false}
          textColor={theme.white}
          tagColor={theme.green}
          text={capitalizeFirstLetter(InboxStatus.COMPLETED)}
        />
      );
    case InboxStatus.SCHEDULED:
      return (
        <NameTag
          isClickable={false}
          textColor={theme.white}
          tagColor={theme.purple500}
          text={capitalizeFirstLetter(InboxStatus.SCHEDULED)}
        />
      );
    case InboxStatus.IN_PROGRESS:
      return (
        <NameTag
          isClickable={false}
          textColor={theme.white}
          tagColor={theme.purple500}
          text={capitalizeFirstLetter(InboxStatus.IN_PROGRESS)}
        />
      );
    case InboxStatus.SNOOZED:
      return (
        <NameTag
          isClickable={false}
          textColor={theme.white}
          tagColor={theme.yellow}
          text={capitalizeFirstLetter(InboxStatus.SNOOZED)}
        />
      );
    case InboxStatus.OVERDUE:
      return (
        <NameTag
          isClickable={false}
          textColor={theme.white}
          tagColor={theme.red}
          text={capitalizeFirstLetter(InboxStatus.OVERDUE)}
        />
      );
    default:
      return null;
  }
};

export default InboxStatusTag;
