import { grayInbox } from '../../assets';
import { Flex, Image, Text } from '../../components';

const EmptySideBar = () => {
  return (
    <Flex
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column">
      <Image src={grayInbox} />
      <Text type="body-regular">Nothing on your to-do list!</Text>
    </Flex>
  );
};

export default EmptySideBar;
