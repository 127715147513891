import { CleaningProductsI, ManageByEnum } from './cleaningProducts.types';

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
}

export enum CodeEnum {
  GENERAL_CLEANING = 'GENERAL_CLEANING',
  KITCHEN_FOOD_PREP = 'KITCHEN_FOOD_PREP',
  WASHROOM_TOILETS = 'WASHROOM_TOILETS',
}

export enum CategoryEnum {
  GENERAL = 'GENERAL',
  REFRIGERATION = 'REFRIGERATION',
  COOKING = 'COOKING',
  WASHING = 'WASHING',
  DYNAMIC_PREPARATION = 'DYNAMIC_PREPARATION',
  FLOOR = 'FLOOR',
  WALL = 'WALL',
  CEILING = 'CEILING',
  TOILETS = 'TOILETS',
  OTHER = 'OTHER',
}

export enum ChemicalEnum {
  CHLORINE_DIOXIDE = 'CHLORINE_DIOXIDE',
}

export enum EquipmentEnum {
  DUSTPAN = 'DUSTPAN',
}

export enum ScheduleEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY',
}

export enum AreaTypeEnum {
  BUILDING = 'BUILDING',
  KITCHEN = 'KITCHEN',
  SANITARY = 'SANITARY',
}
export enum AreaTypeFilterEnum {
  ALL_AREA = 'ALL_AREA',
  BUILDING = 'BUILDING',
  KITCHEN = 'KITCHEN',
  SANITARY = 'SANITARY',
}

export enum LanguageEnum {
  DUTCH = 'DUTCH',
  ENGLISH = 'ENGLISH',
}

export interface EquipmentsItemsI {
  id: string;
  itemName: string;
  image: string;
  status: StatusEnum;
  code: CodeEnum;
  manageBy: ManageByEnum;
}
export interface ItemsI {
  area: AreaTypeEnum;
  category: CategoryEnum;
  colorCode: ColorCodeI;
  icon: string;
  id: string;
  name: ItemNameI;
  schedule: ScheduleEnum[];
  status: StatusEnum;
  code?: string;
  type?: ManageByEnum;
  scheduleDetails: ScheduleDetailsI[];
  venueId?: string;
  defaultItemId?: any;
}
export interface PostItemsI {
  area: AreaTypeEnum;
  category: CategoryEnum;
  colorCode: string;
  icon: File | null;
  name: ItemNameI;
  schedule: ScheduleEnum[];
  code?: string;
  type?: ManageByEnum;
  scheduleDetails: ScheduleDetailsI[];
  venueId?: string;
}

export interface PutItemsI extends PostItemsI {
  id?: string;
}
export interface ItemNameI {
  en: string;
  nl: string;
}
export interface ColorCodeI {
  name: string;
  code: string;
  id?: string;
}

export interface AddItemsScheduleI {
  uploadIcon: File;
  category: CategoryEnum;
  code: string;
  areaType: AreaTypeEnum;
  schedule: ScheduleEnum[];
  titleNl?: string;
  titleEn?: string;
  venueId?: string;
}

export interface ItemLangI {
  en: string;
  nl: string;
}

export interface ScheduleDetailsI {
  schedule: string;
  title: ItemLangI;
  cleaningInstructions: ItemLangI;
  cleaningProducts?: string[] | CleaningProductsI[];
  precautions?: ItemLangI;
  tips?: ItemLangI;
}
export interface PostItemI {
  id: string;
  data: ItemsI;
}

export type ScheduleContentKeys =
  | 'title'
  | 'cleaningInstructions'
  | 'precautions'
  | 'tips';

export type HandleContentChange = {
  title?: string;
  language?: string;
  schedule: string;
  content?: string;
  cleaningProducts?: string[];
};
