import { FC } from 'react';
import ModalComponent from '../../components/modals/ModalComponent';
import { Flex, FormContainer } from '../../components';
import { Col, Form, Row } from 'antd';
import DatePickerComp from '../../components/datePicker/DatepickerComp';
import TimePickerComp from '../../components/timePicker/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { SnoozeTaskI } from './interfaces/inbox.interface';
import moment from 'moment';

interface PropsI {
  onCancel: () => void;
  visible: boolean;
  handleSnooze: (val: SnoozeTaskI) => void;
  requestId: string;
  isLoading: boolean;
}
const SnoozeModal: FC<PropsI> = ({
  onCancel,
  visible,
  handleSnooze,
  requestId,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const handleSubmit = () => {
    const date = form.getFieldValue('date');
    const time = form.getFieldValue('time');
    const dateAndTime = dayjs(
      `${date.format('YYYY-MM-DD')} ${time.format('HH:mm')}`,
    );
    const dateObject = dateAndTime.toDate();
    const utcSnoozeTime = moment(dateObject).utc().format();

    const requestObj: SnoozeTaskI = {
      requestId: requestId,
      snoozedDate: utcSnoozeTime,
    };
    handleSnooze(requestObj);
  };
  return (
    <ModalComponent
      onCancel={onCancel}
      header="Snooze"
      loadingBtn={isLoading}
      disabled={isLoading}
      hideCancel={false}
      onSubmit={() => form && form.submit()}
      visible={visible}
      primaryBtnLabel="Snooze">
      <Flex width="100%">
        <FormContainer
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: 'Choose a date' }]}>
                <DatePickerComp
                  disabledDate={(current: Dayjs) =>
                    current && current < dayjs().startOf('day')
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                label="Time"
                name="time"
                rules={[{ required: true, message: 'Choose a time' }]}>
                <TimePickerComp format={'HH:mm'} size="large" />
              </Form.Item>
            </Col>
          </Row>
        </FormContainer>
      </Flex>
    </ModalComponent>
  );
};

export default SnoozeModal;
