import React, { FC } from 'react';

import { SpaceProps, Tag } from 'antd';
import styled from '@emotion/styled';
import { space } from 'styled-system';
import { Text } from '../';

type SizeType = 'small' | 'large' | 'tiny';
type Props = SpaceProps & {
  tagColor: string;
  textColor: string;
  text: string;
  size?: SizeType;
  tagSize?: SizeType;
  isClickable?: boolean;
};

const CustomTag = styled(Tag)<{ tagSize: SizeType; isClickable: boolean }>`
  height: ${(props) =>
    props.tagSize === 'small'
      ? '20px'
      : props.tagSize === 'tiny'
        ? '15px'
        : '28px'};

  padding: ${(props) =>
    props.tagSize === 'small'
      ? '2px 6px 2px 6px'
      : props.tagSize === 'tiny'
        ? '2px 4px'
        : '3px 12px'};
  font-family: SF Pro Display;
  font-size: ${(props) => (props.tagSize === 'tiny' ? '9px' : '11px')};
  font-weight: 400;
  line-height: ${(props) => (props.tagSize === 'tiny' ? '10px' : '13px')};
  border-radius: 8px;
  cursor: ${(props) => (props.isClickable ? 'pointer' : '')};
  ${space};
`;

const NameTag: FC<Props> = ({
  tagColor,
  textColor,
  text,
  size = 'large',
  tagSize,
  isClickable = true,
}) => {
  return (
    <CustomTag
      color={tagColor}
      tagSize={tagSize ? tagSize : size}
      isClickable={isClickable}>
      {tagSize === 'tiny' ? (
        <Text type="tiny" color={textColor}>
          {text}
        </Text>
      ) : size === 'large' ? (
        <Text type="regular" color={textColor}>
          {text}
        </Text>
      ) : (
        <Text type="caption2-regular" color={textColor}>
          {text}
        </Text>
      )}
    </CustomTag>
  );
};

export default NameTag;
