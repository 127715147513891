import { FC } from 'react';
import { Image } from 'antd';
import { Flex } from '../flex/Flex';
import { ImageProps } from 'antd';
import styled from '@emotion/styled';
import theme from '../../theme';
import { SpaceProps, space } from 'styled-system';
import { TealCloseIcon } from '../../assets';

const ImageContainer = styled(Flex)<SpaceProps>`
  position: relative;
  padding: 4px;
  border: 1px dashed ${theme.gray400};
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${space}
`;

const CloseIcon = styled(Flex)`
  position: absolute;
  top: -8px;
  right: -10px;
  cursor: pointer;
  z-index: 1;
`;

interface ImagePreviewProps extends ImageProps, SpaceProps {
  maxHeight?: string;
  maxWidth?: string;
  showCloseIcon?: boolean;
  onClose?: () => void;
}

const ImagePreview: FC<ImagePreviewProps> = (props) => {
  const {
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    maxHeight = '200px',
    maxWidth,
    showCloseIcon = false,
    onClose,
    ...restProps
  } = props;

  return (
    <ImageContainer
      m={m}
      mt={mt}
      mr={mr}
      mb={mb}
      ml={ml}
      mx={mx}
      my={my}
      p={p}
      pt={pt}
      pr={pr}
      pb={pb}
      pl={pl}
      px={px}
      py={py}>
      {showCloseIcon && (
        <CloseIcon onClick={onClose}>
          <TealCloseIcon height={20} width={20} />
        </CloseIcon>
      )}
      <Image
        {...restProps}
        style={{ objectFit: 'cover', maxHeight, maxWidth }}
      />
    </ImageContainer>
  );
};

export default ImagePreview;
