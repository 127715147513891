import React from 'react';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { Flex } from '../flex/Flex';
import styled from '@emotion/styled';
import theme from '../../theme';

const { RangePicker } = DatePicker;

const CustomRangePicker = styled(RangePicker)`
  width: 100%;
  height: 40px;
  border: 0.5px solid ${theme.gray400} !important;
  border-radius: 8px;
`;

const DateRangePicker: React.FC<RangePickerProps> = (props) => {
  return (
    <Flex display="flex">
      <CustomRangePicker color={theme.brandTeal} {...props} />
    </Flex>
  );
};

export default DateRangePicker;
