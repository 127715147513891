import React from 'react';
import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';
import { Image } from '../../components/image/Image';
import { Text } from '../../components/typography/Text';
import theme from '../../theme';
import { Flex } from '../../components/flex/Flex';
import styled from '@emotion/styled';
import { OrgVenuesI } from '../../types/venue.types';
import { CollapseIcon } from '../../assets';

const { Panel } = Collapse;

interface CustomCollapseProps extends CollapseProps {
  children: React.ReactNode;
  venue: OrgVenuesI;
}

const StyledCollapse = styled(Collapse)`
  border: 1px solid ${theme.gray400};
  background: ${theme.white};
  .ant-collapse-header {
    padding: 16px !important;
    align-items: center !important;
  }
  .ant-collapse-item {
    border: none !important;
  }
  .custom-collapse-header {
    display: flex;
    align-items: center;
  }
  .ant-collapse-content-box {
    padding: 0px 0px 16px 16px !important;
  }
`;

const CustomCollapse: React.FC<CustomCollapseProps> = ({
  children,
  venue,
  ...props
}) => {
  return (
    <StyledCollapse
      {...props}
      defaultActiveKey={'1'}
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <Flex display="flex" alignItems="center">
          <Text type="callout-regular" mr={'8px'} color={theme.brandTeal}>
            {isActive ? 'Show less' : 'Show more'}
          </Text>
          <Image
            src={CollapseIcon}
            alt="collapse icon"
            style={{
              transform: isActive ? 'rotate(90deg)' : 'rotate(270deg)',
              transition: 'transform 0.3s',
            }}
          />
        </Flex>
      )}>
      <Panel
        header={
          <div className="custom-collapse-header">
            <Image
              src={venue.image}
              alt="header icon"
              className="header-image"
              height="40px"
              width="40px"
              borderRadius="20px"
              marginRight="8px"
            />
            <Flex>
              <Text type="callout-bold">{venue.name}</Text>
              <Text type="footnote-regular">{venue.city}</Text>
            </Flex>
          </div>
        }
        key="1">
        {children}
      </Panel>
    </StyledCollapse>
  );
};

export default CustomCollapse;
