import { io, Socket } from 'socket.io-client';
import { BASE_URL } from '../../configs/appConfig';

const socket: Socket = io(`${BASE_URL}/sockets`);
export const connectSocket = () => {
  socket.connect();
};

export const disconnectSocket = () => {
  socket.disconnect();
};

export const listenToEvent = (event: string, callback: (data: any) => void) => {
  if (!socket.connected) {
    socket.on('connect', () => {
      socket.on(event, callback);
    });
  } else {
    socket.on(event, callback);
  }
};

export const emitEvent = (event: string, data?: any) => {
  socket.emit(event, data);
};

export default socket;
