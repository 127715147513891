import { useMutation, useQuery } from 'react-query';
import { http } from './_apiService';
import { ApiError } from './_types';
import { PostTipsI, PutTipsI, TipsI } from '../types/tips.types';
import { Message } from '../components/message/Message';

export const useGetTipList = () => {
  return useQuery<TipsI[], ApiError>(
    'getTips',
    async () => {
      const response = await http.get('/tips');
      return response.data;
    },
    {
      enabled: false,
    },
  );
};

export const useGetTip = () => {
  return useMutation<TipsI, ApiError, string>(async (id) => {
    const response = await http.get(`/tips/${id}`);
    return response.data;
  });
};

export const useCreateTip = () => {
  return useMutation<string, ApiError, PostTipsI>(
    async (data: PostTipsI) => {
      const formData = new FormData();

      formData.append('name', JSON.stringify(data.name));
      formData.append('area', data.area);
      formData.append('colorCode', data.colorCode);
      data?.icon && formData.append('icon', data.icon);
      formData.append('scheduleDetails', JSON.stringify(data.scheduleDetails));

      const response = await http.post(`/tips`, formData);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Tip added successfully');
      },
    },
  );
};

export const useUpdateTip = () => {
  return useMutation<string, ApiError, PutTipsI>(
    async (data: PutTipsI) => {
      const formData = new FormData();

      formData.append('name', JSON.stringify(data.name));
      formData.append('area', data.area);
      formData.append('colorCode', data.colorCode);
      data?.icon && formData.append('icon', data.icon);
      formData.append('scheduleDetails', JSON.stringify(data.scheduleDetails));

      const response = await http.put(`/tips/${data.id}`, formData);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Tip updated successfully');
      },
    },
  );
};

export const useToggleTipStatus = () => {
  return useMutation<string, ApiError, string>(async (id) => {
    const response = await http.put(`/tips/toggle/${id}`);
    return response.data;
  });
};
