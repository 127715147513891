import { FC, useEffect, useState } from 'react';
import ModalComponent from '../../components/modals/ModalComponent';
import { Col, Form, Row, Select } from 'antd';
import { Flex, FormContainer, SelectComp, Text } from '../../components';
import CustomTextArea from '../../components/TextArea/CustomTextArea';
import { useGetVenues } from '../../api/venueHooks';
import { ItemsI } from '../../types/equipmentsItems.types';
import { InboxAreaI, PostRequestI } from './interfaces/inbox.interface';
import { InboxType } from '../enums/Inbox.enum';
import DragAndDropFileUpload from '../../components/fileUpload/DragAndDropFileUpload';
import ImagePreview from '../../components/image/ImagePreview';

interface PropsI {
  visible: boolean;
  onCancel: () => void;
  postRequest: (data: PostRequestI) => void;
  isLoading: boolean;
}
const CreateRequestModal: FC<PropsI> = ({
  visible,
  onCancel,
  postRequest,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const [venue, setVenue] = useState<string>();
  const { data: venues, refetch: getVenues } = useGetVenues();
  const [areas, setAreas] = useState<InboxAreaI[]>([]);
  const [items, setItems] = useState<ItemsI[]>([]);
  const [area, setArea] = useState<string>();

  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  useEffect(() => {
    getVenues();
    if (venues) {
      form.setFieldValue('venue', venues[0].id);
      setVenue(venues[0].id);
    }
  }, []);

  useEffect(() => {
    if (venue) {
      const venueObj = venues?.find((venueItem) => venueItem.id === venue);

      if (venueObj && venueObj.areas.length > 0) {
        setAreas(venueObj.areas);
      }
    }
  }, [venue, venues]);

  useEffect(() => {
    if (area) {
      const areaObj = areas.find((areaVal) => areaVal.id === area);
      const areasObj = areaObj?.items;
      if (areasObj) setItems(areaObj.items);
    }
  }, [area, areas]);

  const handleSetFormImage = (file: File) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages, file].slice(0, 3);
      return updatedImages;
    });

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviews((prevPreviews) => {
        const updatedPreviews = [
          ...prevPreviews,
          reader.result as string,
        ].slice(0, 3);
        return updatedPreviews;
      });
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, i) => i !== index),
    );
  };

  const handleSubmit = () => {
    const data: PostRequestI = {
      ...form.getFieldsValue(),
      images: images,
      type: InboxType.REQUEST,
    };
    postRequest(data);
  };
  return (
    <ModalComponent
      onCancel={onCancel}
      onSubmit={() => form && form.submit()}
      header="Request"
      loadingBtn={isLoading}
      disabled={isLoading}
      hideCancel={false}
      primaryBtnLabel="Add"
      visible={visible}>
      <Flex width="100%">
        <FormContainer
          layout="vertical"
          form={form}
          validateTrigger="onBlur"
          onFinish={handleSubmit}>
          <Form.Item label="Venue" name="venue">
            <SelectComp
              onChange={(val) => setVenue(val as string)}
              defaultValue={venues ? venues[0].id : ''}>
              {venues?.map((venue) => (
                <Select.Option key={venue.id} value={venue.id}>
                  {venue.name}
                </Select.Option>
              ))}
            </SelectComp>
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item label="Area" name="area">
                <SelectComp
                  onChange={(val) => setArea(val as string)}
                  disabled={!venue}>
                  {areas?.map((area) => (
                    <Select.Option key={area.id} value={area.id}>
                      {area.name}
                    </Select.Option>
                  ))}
                </SelectComp>
              </Form.Item>
            </Col>
            {area && (
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item label="Item" name="item">
                  <SelectComp disabled={!area}>
                    {items?.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name ? item.name.en : item.defaultItemId.name.en}
                      </Select.Option>
                    ))}
                  </SelectComp>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item
            label="Issue Description"
            name="description"
            rules={[
              { required: true, message: 'Please enter the description' },
            ]}>
            <CustomTextArea
              placeholder="Enter the request description"
              value="Machine is not turning on, despite my attempts to troubleshoot the issue by checking the power supply, pressing the power button, and ensuring that all cables are securely connected. I also tried unplugging the machine, waiting for a few minutes, and plugging it back in, but the problem persists."
            />
          </Form.Item>
          <Text type="subheading-regular" mb="8px">
            Images (Optional)
          </Text>
          <Flex display="flex" flexWrap="wrap">
            {imagePreviews.map((imageLink, index) => (
              <Flex
                key={index}
                display="flex"
                flexWrap="wrap"
                height="140px"
                maxHeight="140px"
                maxWidth="140px"
                width="140px">
                <ImagePreview
                  src={imageLink}
                  m="0px 16px 16px 0px"
                  maxHeight="150px"
                  maxWidth="150px"
                  showCloseIcon={true}
                  onClose={() => handleRemoveImage(index)}
                />
              </Flex>
            ))}
            {imagePreviews.length < 3 ? (
              <Form.Item name="image1" style={{ marginRight: '16px' }}>
                <DragAndDropFileUpload
                  accept={['image/jpeg', 'image/png']}
                  minSize={1024}
                  maxSize={5242880}
                  multiple={false}
                  setFormImage={handleSetFormImage}
                  showPreview={false}
                />
              </Form.Item>
            ) : (
              <></>
            )}
          </Flex>
        </FormContainer>
      </Flex>
    </ModalComponent>
  );
};

export default CreateRequestModal;
