import { FC } from 'react';
import Table from 'antd/es/table';
import { Flex, Text } from '../../components';
import styled from '@emotion/styled';
import { TableProps } from 'antd';
import theme from '../../theme';
import { DailyTaskStatus, DateFrequencyEnum } from '../../types/report.types';
import { GreenCheckRound, RedCrossRound } from '../../assets';
import { getNoOfCols } from '../../utils/reportUtil';

interface Props<T extends object = any> extends TableProps<T> {
  dataSource: any;
  venueKeys: number[];
  schedule: DateFrequencyEnum;
  venue?: string;
  height?: string;
}

const ReportTableStyled = styled(Table)<Props>`
  .ant-table-tbody > tr > td {
    padding: 0px;
  }

  .first-column {
    background-color: ${theme.gray100};
  }
  .date-row {
    background-color: ${theme.gray100};
  }
  .task-names {
    background-color: ${theme.white} !important;
  }
  .ant-table-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-table {
    table-layout: fixed;
  }
`;

const TableContainer = styled.div<{ days: number }>`
  max-width: ${(props) =>
    props.days === 14 ? '800px' : props.days === 7 ? '500px' : 'auto'};
  overflow-x: auto;
`;

const NotCompletedFlex = styled(Flex)`
  background: ${theme.red50} !important;
`;

const ReportTable: FC<Props> = ({ ...props }) => {
  const areaNameArr = props.venueKeys;
  const { schedule } = props;

  const noOfCols = getNoOfCols(schedule);

  const generateLastNDaysColumns = () => {
    const dayColumns = [];
    for (let i = 1; i < noOfCols; i++) {
      dayColumns.push({
        dataIndex: `date_${i + 1}`,
        key: `date_${i + 1}`,
        width: 45,
        render: (text: string, row: any) => {
          if (row.key === 1 || areaNameArr.includes(row.key)) {
            return {
              children: null,
              props: { colSpan: 0 },
            };
          } else if (row.venue === 'Date') {
            return {
              children: (
                <Text type="caption1-regular" m="6px 9px">
                  {text}
                </Text>
              ),
              props: {
                className: 'date-row',
              },
            };
          } else {
            const backgroundColor =
              text === DailyTaskStatus.NOT_COMPLETED
                ? theme.red50
                : 'transparent';

            return {
              children:
                text === DailyTaskStatus.COMPLETED ? (
                  <Flex
                    display="flex"
                    justifyContent="center"
                    padding="6px 9px">
                    <GreenCheckRound />
                  </Flex>
                ) : text === DailyTaskStatus.NOT_COMPLETED ? (
                  <NotCompletedFlex
                    display="flex"
                    justifyContent="center"
                    padding="6px 9px">
                    <RedCrossRound />
                  </NotCompletedFlex>
                ) : text === DailyTaskStatus.NA ? (
                  <Text
                    type="caption1-regular"
                    textAlign="center"
                    padding="6px 9px"
                    color={theme.gray800}>
                    n/a
                  </Text>
                ) : (
                  <Text type="caption1-regular" m="6px 9px">
                    {text}
                  </Text>
                ),
              props: { colSpan: 1, style: { backgroundColor } },
            };
          }
        },
      });
    }

    return dayColumns;
  };

  const columns = [
    {
      dataIndex: 'venue',
      key: 'venue',
      width: 150,
      className: 'first-column',
      render: (text: string, row: any) => {
        if (areaNameArr.includes(row.key)) {
          return {
            children: (
              <Text type="footnote-bold" m="8px 16px">
                {text}
              </Text>
            ),
            props: {
              colSpan: noOfCols + 1,
            },
          };
        }
        return {
          children: (
            <Text type="footnote-regular" m="8px 16px">
              {text}
            </Text>
          ),
          props: {
            className: row.key > 5 ? 'task-names' : 'first-column',
          },
        };
      },
    },
    {
      dataIndex: 'date_1',
      key: 'venueName',
      width: 45,
      render: (text: string, row: any, index: number) => {
        if (index === 0) {
          return {
            children: (
              <Text m="6px 9px" type="caption1-regular">
                {text}
              </Text>
            ),
            props: {
              colSpan: noOfCols,
            },
          };
        } else if (areaNameArr.includes(row.key)) {
          return {
            children: null,
            props: {
              colSpan: 0,
            },
          };
        } else if (row.venue === 'Date') {
          return {
            children: (
              <Text type="caption1-regular" m="6px 9px">
                {text}
              </Text>
            ),
            props: {
              className: 'date-row',
            },
          };
        } else {
          return {
            children:
              text === DailyTaskStatus.COMPLETED ? (
                <Flex display="flex" justifyContent="center">
                  <GreenCheckRound />
                </Flex>
              ) : text === DailyTaskStatus.NOT_COMPLETED ? (
                <NotCompletedFlex
                  display="flex"
                  justifyContent="center"
                  padding="10px 9px">
                  <RedCrossRound />
                </NotCompletedFlex>
              ) : text === DailyTaskStatus.NA ? (
                <Text
                  type="caption1-regular"
                  textAlign="center"
                  color={theme.gray800}>
                  n/a
                </Text>
              ) : (
                <Text type="caption1-regular" m="6px 9px">
                  {text}
                </Text>
              ),
          };
        }
      },
    },
    ...generateLastNDaysColumns(),
  ];

  return (
    <TableContainer days={noOfCols}>
      <ReportTableStyled
        {...props}
        bordered={true}
        showHeader={false}
        pagination={false}
        columns={columns}
        dataSource={props.dataSource}
        tableLayout="fixed"
        scroll={{
          x: 'max-content',
        }}
      />
    </TableContainer>
  );
};

export default ReportTable;
