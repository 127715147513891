/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import type { TableProps } from 'antd';
import { Flex as AntdFlex, Table } from 'antd';
import { FC, ReactNode } from 'react';
import { Flex, H, PrimaryButton } from '..';
import { CirclePlus, EmptySVG } from '../../assets';
import theme from '../../theme';

interface Props<T extends object = any> extends TableProps<T> {
  withBorders?: boolean;
  rowSize?: number;
  headerFontSize?: number;
  onClick?: () => void;
  emptyInfoText?: string | ReactNode;
  height?: string;
  isCalendar?: boolean;
}
const StyledTable = styled(Table)<Props>`
  border-radius: 0;
  height: ${(props) => (props.height ? props.height : '80vh')};
  ${(props) =>
    props.withBorders &&
    `
      border:  1px solid ${theme.gray300};
      border-bottom:unset;
      border-radius: 0;
    `}
  .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
    background: ${(props) => (props.isCalendar ? theme.white : theme.gray200)};
    padding: ${(props) =>
      props.isCalendar ? '8px 24px' : '8px 0px 8px 24px'} !important;
    border-radius: 0 !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 0.5px solid ${theme.gray400} !important;
    padding: ${(props) =>
      props.isCalendar ? '0px' : '8px 0px 8px 24px'} !important;
  }
  .ant-table-column-sorter-inner {
    display: none !important;
  }
  .ant-table-wrapper .ant-table-expanded-row-fixed {
    padding: 0px;
  }
`;

const TableComponent: FC<Props> = ({
  withBorders = false,
  onClick,
  emptyInfoText,
  height,
  isCalendar,
  ...props
}) => {
  const locale = {
    emptyText: (
      <Flex margin={`80px 0px`}>
        <img src={EmptySVG} />
        {emptyInfoText ? (
          <H type="headlineBold" color={theme.brandDarkTeal}>
            {emptyInfoText}
          </H>
        ) : (
          <H type="headlineBold" color={theme.brandDarkTeal}>
            No items added yet.
          </H>
        )}
        <AntdFlex justify="center">
          {onClick && (
            <PrimaryButton
              marginTop="16px"
              size="middle"
              icon={<CirclePlus />}
              onClick={() => onClick()}>
              Add Item
            </PrimaryButton>
          )}
        </AntdFlex>
      </Flex>
    ),
  };

  return (
    <>
      <StyledTable
        {...props}
        height={height}
        pagination={false}
        withBorders={withBorders}
        locale={locale}
        isCalendar={isCalendar}
      />
    </>
  );
};

export default TableComponent;
