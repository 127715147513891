import { FC, useEffect, useState } from 'react';
import {
  Flex,
  Image,
  MenuItem,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '../../components';
import styled from '@emotion/styled';
import theme from '../../theme';
import { Col, Dropdown, Menu, Row, Spin, Tooltip } from 'antd';
import { CreamPlus, FilterIcon } from '../../assets';
import EmptyListView from './EmptyListView';
import EmptySideBar from './EmptySideBar';
import InboxItemCard from './InboxItemCard';
import ScheduleModal from './ScheduleModal';
import SnoozeModal from './SnoozeModal';
import CreateTodoModal from './CreateTodoModal';
import InboxIcon from './InboxIcon';
import {
  addDotsForLongText,
  capitalizeFirstLetter,
} from '../../utils/textUtil';
import InboxItemView from './InboxItemView';
import CreateRequestModal from './CreateRequestModal';
import SnoozeButton from '../../components/dropdown/SnoozeButton';
import {
  useCompleteTodo,
  useGetRequests,
  useMarkAsRead,
  usePostRequest,
  useSnoozeTask,
} from '../../api/requestHooks';
import { RequestI } from './interfaces/inbox.interface';
import InboxStatusTag from './InboxStatusTag';
import { InboxStatus, InboxType } from '../enums/Inbox.enum';
import useWebSocket from '../../api/sockets/websocketHook';
import { connectSocket } from '../../api/sockets/socketServices';
import InboxLegends from './InboxLegends';
import { isHaveThreeDots } from '../../utils/threeDotPopoverUtil';

interface TagI {
  selected: boolean;
}
interface AllTagI {
  selectedType?: InboxType;
}

const Tag = styled.div<TagI>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 8px 16px 8px 16px;
  ${(props) =>
    props.selected &&
    `
    background-color: ${theme.gray200};
    border-radius: 8px;
    padding: 4px;
    border: 1px solid ${theme.gray400};
    height: 36px;
  `}
`;

const AllTag = styled.div<AllTagI>`
  margin: 8px 16px;
  ${(props) =>
    !props.selectedType &&
    `
  padding: 7.5px 16px;
  background-color: ${theme.gray200};
  border-radius: 8px;
  border: 1px solid ${theme.gray400};
  height: 36px;
  `}
  cursor: pointer;
`;

const SideBar = styled.div`
  background-color: ${theme.white};
  border-radius: 8px;
  height: 85vh;
  border: 1px solid ${theme.gray400};
  box-shadow: 0px 2px 6px 0px rgba(210, 210, 210, 0.5);
  display: flex;
  flex-direction: column;
`;
const MainView = styled.div`
  background-color: ${theme.white};
  border-radius: 8px;
  height: 85vh;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.gray400};
  box-shadow: 0px 2px 6px 0px rgba(210, 210, 210, 0.5);
`;

const DropDownBtn = styled(PrimaryButton)`
  width: 22px !important;
  height: 22px;
  border-radius: 8px;
`;

const FilterBar = styled(Flex)`
  height: 32px;
  border-top: 1px solid ${theme.gray300};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Inbox: FC = () => {
  const [visibleScheduleModal, setVisibleScheduleModal] =
    useState<boolean>(false);
  const [visibleCreateTodoModal, setVisibleCreateTodoModal] =
    useState<boolean>(false);
  const [visibleSnoozeModal, setVisibleSnoozeModal] = useState<boolean>(false);
  const [visibleRequestModal, setRequestModal] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<RequestI>();
  const [selectedType, setSelectedType] = useState<InboxType>();
  const [filteredRequests, setFilteredRequests] = useState<RequestI[]>();

  const {
    data: requests,
    refetch: fetchRequests,
    isLoading: isRequestsLoading,
  } = useGetRequests();
  const {
    mutate: createRequest,
    isLoading: isLoadingPostRequest,
    isSuccess: isSuccessPostRequest,
  } = usePostRequest();
  const {
    mutate: completeTodo,
    isSuccess: completeTodoSuccess,
    isLoading: isLoadingTodoComplete,
  } = useCompleteTodo();
  const {
    mutate: snoozeTask,
    isSuccess: isSuccessSnooze,
    isLoading: isLoadingSnooze,
  } = useSnoozeTask();
  const { mutate: markAsRead } = useMarkAsRead();

  useEffect(() => {
    connectSocket();
    fetchRequests();
  }, []);

  // Filtering requests
  useEffect(() => {
    if (!selectedType) {
      setFilteredRequests(requests);
    } else {
      const filteredReqs = requests?.filter((req) => req.type === selectedType);
      setFilteredRequests(filteredReqs);
    }
  }, [selectedType]);

  useEffect(() => {
    if (completeTodoSuccess) {
      fetchRequests();
    }
  }, [completeTodoSuccess]);

  useEffect(() => {
    if (selectedRequest) {
      if (!selectedRequest.isRead) {
        markAsRead(selectedRequest.id);
        setFilteredRequests((prevRequests) =>
          prevRequests?.map((item) =>
            item.id === selectedRequest.id ? { ...item, isRead: true } : item,
          ),
        );
      }
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (filteredRequests) {
      if (selectedRequest) {
        const { id, type } = selectedRequest;
        const matchingReq = filteredRequests.find(
          (val) => val.id === id && val.type === type,
        );
        if (matchingReq) {
          setSelectedRequest(matchingReq);
        } else {
          setSelectedRequest(filteredRequests[0]);
        }
      } else {
        setSelectedRequest(filteredRequests[0]);
      }
    } else {
      if (requests) setSelectedRequest(requests[0]);
    }
  }, [filteredRequests]);

  useEffect(() => {
    if (requests && selectedRequest) {
      const oldId = selectedRequest.id;
      const newreq = requests.find((val) => val.id === oldId);
      setSelectedRequest(newreq);
    }
    if (!selectedType) {
      setFilteredRequests(requests);
    } else {
      const filteredReqs = requests?.filter((req) => req.type === selectedType);
      setFilteredRequests(filteredReqs);
    }
  }, [requests]);

  useEffect(() => {
    if (isSuccessPostRequest) {
      setRequestModal(false);
      setVisibleCreateTodoModal(false);
      fetchRequests();
    }
  }, [isSuccessPostRequest]);

  useEffect(() => {
    if (isSuccessSnooze) {
      setVisibleSnoozeModal(false);
      fetchRequests();
    }
  }, [isSuccessSnooze]);

  useWebSocket('snoozed', (data) => {
    if (data.action === 'refresh') {
      fetchRequests();
    }
  });

  return (
    <Spin
      spinning={
        isRequestsLoading || isLoadingPostRequest || isLoadingTodoComplete
      }>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={24} lg={8}>
          <SideBar>
            <Flex
              display="flex"
              justifyContent="space-between"
              m="16px 24px 16px 24px">
              <Text type="body-bold">Inbox</Text>
              <Dropdown
                dropdownRender={() => (
                  <Menu>
                    <MenuItem
                      key="request"
                      onClick={() => setRequestModal(true)}>
                      Request
                    </MenuItem>
                    <MenuItem
                      key="todo"
                      onClick={() => setVisibleCreateTodoModal(true)}>
                      Todo
                    </MenuItem>
                  </Menu>
                )}>
                <DropDownBtn icon={<CreamPlus />} />
              </Dropdown>
            </Flex>
            <FilterBar>
              <InboxLegends />
              <Dropdown
                dropdownRender={() => (
                  <Menu
                    style={{
                      width: '181px',
                      paddingTop: '8px',
                      paddingBottom: '8px',
                    }}>
                    <AllTag
                      selectedType={selectedType}
                      onClick={() => setSelectedType(undefined)}>
                      All
                    </AllTag>
                    {Object.values(InboxType).map((type) => (
                      <>
                        <Tag
                          selected={selectedType === type}
                          onClick={() => setSelectedType(type)}>
                          <InboxIcon type={type} alwaysMargin={true} />
                          <Text type="callout-regular">
                            {capitalizeFirstLetter(type)}
                          </Text>
                        </Tag>
                      </>
                    ))}
                  </Menu>
                )}>
                <Image src={FilterIcon} ml="16px" mr="24px" />
              </Dropdown>
            </FilterBar>
            <Flex
              borderTop={`1px solid ${theme.gray400}`}
              display="flex"
              height="100%"
              overflow="auto"
              flexDirection="column"
              alignItems={`${filteredRequests && filteredRequests.length > 0 ? '' : 'center'}`}
              justifyContent={`${filteredRequests && filteredRequests.length > 0 ? '' : 'center'}`}>
              {filteredRequests && filteredRequests.length > 0 ? (
                filteredRequests.map((item, index) => (
                  <Flex key={index} onClick={() => setSelectedRequest(item)}>
                    <InboxItemCard
                      key={index}
                      selected={selectedRequest?.id === item.id}
                      isRead={item.isRead}
                      description={item.description}
                      date={item.lastUpdated}
                      status={item.status}
                      type={item.type}
                    />
                  </Flex>
                ))
              ) : (
                <EmptySideBar />
              )}
            </Flex>
          </SideBar>
        </Col>
        <Col xs={24} md={24} lg={16}>
          <MainView>
            {!filteredRequests || filteredRequests.length === 0 ? (
              <EmptyListView addNewItem={setVisibleCreateTodoModal} />
            ) : (
              <>
                <Flex
                  display="flex"
                  justifyContent="space-between"
                  borderBottom={`1px solid ${theme.gray400}`}
                  paddingRight={'16px'}
                  mt={'16px'}>
                  <Flex
                    display="flex"
                    alignItems="center"
                    padding="0px 24px 16px 24px">
                    <InboxIcon
                      type={
                        selectedRequest
                          ? selectedRequest.type
                          : filteredRequests[0].type
                      }
                      alwaysMargin={true}
                    />
                    <Tooltip
                      title={
                        isHaveThreeDots(
                          `${selectedRequest ? (selectedRequest.title ? selectedRequest.title : selectedRequest.description) : filteredRequests[0].description}`,
                          80,
                        ) ? (
                          <span style={{ color: theme.black }}>
                            {selectedRequest &&
                              (selectedRequest.title
                                ? selectedRequest.title
                                : selectedRequest.description)}
                          </span>
                        ) : (
                          ''
                        )
                      }
                      color={theme.white}>
                      {}
                      <Text type="body-bold">
                        {addDotsForLongText(
                          `${selectedRequest ? (selectedRequest.title ? selectedRequest.title : selectedRequest.description) : filteredRequests[0].description}`,
                          80,
                        )}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <InboxStatusTag
                    status={
                      selectedRequest
                        ? selectedRequest.status
                        : filteredRequests[0].status
                    }
                  />
                </Flex>
                {selectedRequest && <InboxItemView request={selectedRequest} />}
                {selectedRequest &&
                  selectedRequest.type !== InboxType.SCHEDULED && (
                    <Flex
                      display="flex"
                      alignItems={'center'}
                      borderTop={`1px solid ${theme.gray400}`}
                      justifyContent="right"
                      height="61px"
                      minHeight="61px">
                      {selectedRequest.status !== InboxStatus.SNOOZED && (
                        <SnoozeButton
                          handleSnooze={snoozeTask}
                          requestId={selectedRequest.id}
                          showModal={setVisibleSnoozeModal}
                        />
                      )}
                      <>
                        <SecondaryButton
                          mr={'16px'}
                          ml={'16px'}
                          onClick={() => setVisibleScheduleModal(true)}>
                          Schedule
                        </SecondaryButton>
                        <PrimaryButton
                          mr="24px"
                          disabled={isRequestsLoading || isLoadingTodoComplete}
                          loading={isRequestsLoading || isLoadingTodoComplete}
                          onClick={() =>
                            selectedRequest && completeTodo(selectedRequest.id)
                          }>
                          {`Mark as ${
                            selectedRequest &&
                            selectedRequest.status === InboxStatus.COMPLETED
                              ? 'Incomplete'
                              : 'Completed'
                          }`}
                        </PrimaryButton>
                      </>
                    </Flex>
                  )}
              </>
            )}
          </MainView>
        </Col>
        {visibleScheduleModal && selectedRequest && (
          <ScheduleModal
            selectedRequest={selectedRequest}
            visible={visibleScheduleModal}
            onCancel={() => setVisibleScheduleModal(false)}
            refetchRequests={() => fetchRequests()}
          />
        )}
        {visibleCreateTodoModal && (
          <CreateTodoModal
            visible={visibleCreateTodoModal}
            onCancel={() => setVisibleCreateTodoModal(false)}
            postTodo={createRequest}
            isLoading={isLoadingPostRequest}
          />
        )}
        {selectedRequest && visibleSnoozeModal && (
          <SnoozeModal
            visible={visibleSnoozeModal}
            requestId={selectedRequest.id}
            handleSnooze={snoozeTask}
            onCancel={() => setVisibleSnoozeModal(false)}
            isLoading={isLoadingSnooze}
          />
        )}
        {visibleRequestModal && (
          <CreateRequestModal
            visible={visibleRequestModal}
            onCancel={() => setRequestModal(false)}
            postRequest={createRequest}
            isLoading={isLoadingPostRequest}
          />
        )}
      </Row>
    </Spin>
  );
};

export default Inbox;
