import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { Text } from '../../components';
import {
  HandleContentChange,
  ScheduleDetailsI,
} from '../../types/equipmentsItems.types';
import { AddCleaningProducts } from './AddCleaningProducts';
import { QuillTextEditor } from './QuillTextEditor';
import { TipsInformationI } from '../../types/tips.types';

interface ScheduleLayoutProps {
  initialValue?: ScheduleDetailsI | TipsInformationI;
  label: string;
  handleContentChange: (v: HandleContentChange) => void;
}

const EditorCol = styled(Col)`
  padding: 12px 12px 12px 12px;
`;

export const ScheduleLayout: FC<ScheduleLayoutProps> = ({
  initialValue,
  label,
  handleContentChange,
}) => {
  return (
    <>
      <Row>
        <EditorCol xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Text type="subheading-regular" mb="6px">
            Task Title
          </Text>
          <QuillTextEditor
            title="title"
            language="en"
            schedule={label}
            initialValue={initialValue?.title.en}
            onContentChange={handleContentChange}
            placeholder="Write task title here"
            height="106px"
            hideToolBar={true}
            noRichText={true}
          />
        </EditorCol>
        <EditorCol xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Text type="subheading-regular" mb="6px">
            Titel van de taak
          </Text>
          <QuillTextEditor
            title="title"
            language="nl"
            schedule={label}
            initialValue={initialValue?.title.nl}
            onContentChange={handleContentChange}
            placeholder="Schrijf hier de taaktitel"
            height="106px"
            hideToolBar={true}
            noRichText={true}
          />
        </EditorCol>
      </Row>
      <Row>
        <EditorCol xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Text type="subheading-regular" mb="6px">
            Cleaning Instructions
          </Text>
          <QuillTextEditor
            title="cleaningInstructions"
            language="en"
            schedule={label}
            initialValue={initialValue?.cleaningInstructions.en}
            onContentChange={handleContentChange}
            placeholder="Write cleaning instructions here"
          />
        </EditorCol>
        <EditorCol xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Text type="subheading-regular" mb="6px">
            Reinigingsinstructies
          </Text>
          <QuillTextEditor
            title="cleaningInstructions"
            language="nl"
            schedule={label}
            initialValue={initialValue?.cleaningInstructions.nl}
            onContentChange={handleContentChange}
            placeholder="Schrijf hier reinigingsinstructies"
          />
        </EditorCol>
      </Row>

      <Row>
        <EditorCol xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Text type="subheading-regular" mb="6px">
            Precautions
          </Text>
          <QuillTextEditor
            title="precautions"
            language="en"
            schedule={label}
            initialValue={initialValue?.precautions?.en}
            onContentChange={handleContentChange}
            placeholder="Write precautions here"
          />
        </EditorCol>
        <EditorCol xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Text type="subheading-regular" mb="6px">
            Voorzorgsmaatregelen
          </Text>
          <QuillTextEditor
            title="precautions"
            language="nl"
            schedule={label}
            initialValue={initialValue?.precautions?.nl}
            onContentChange={handleContentChange}
            placeholder="Schrijf hier voorzorgsmaatregelen"
          />
        </EditorCol>
      </Row>
      <Row>
        <EditorCol xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Text type="subheading-regular" mb="6px">
            Safety Tips
          </Text>
          <QuillTextEditor
            title="tips"
            language="en"
            schedule={label}
            initialValue={initialValue?.tips?.en}
            onContentChange={handleContentChange}
            placeholder="Write safety tips here"
          />
        </EditorCol>
        <EditorCol xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Text type="subheading-regular" mb="6px">
            Veiligheidstips
          </Text>
          <QuillTextEditor
            title="tips"
            language="nl"
            schedule={label}
            initialValue={initialValue?.tips?.nl}
            onContentChange={handleContentChange}
            placeholder="Schrijf hier veiligheidstips"
          />
        </EditorCol>
      </Row>

      <Row>
        <EditorCol xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <AddCleaningProducts
            initialValues={initialValue?.cleaningProducts}
            handleCleaningProducts={(cleaningProducts) =>
              handleContentChange({
                schedule: label,
                cleaningProducts,
              })
            }
          />
        </EditorCol>
      </Row>
    </>
  );
};
