import { Layout as AntdLayout } from 'antd';
import LayoutHeader from './LayoutHeader';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from '../../theme';
import { useWindowSize } from '../../utils/screenSize';

const { Content } = AntdLayout;

const ContentLayout = styled(Content)`
  min-height: 93vh;
  margin-top: 68px;
  background-color: ${theme.gray200};
  padding: 24px;
`;

const Layout = () => {
  const { isDesktopLarge, isTablet } = useWindowSize();
  return (
    <AntdLayout
      style={{
        minHeight: '100vh',
      }}>
      <AntdLayout>
        <LayoutHeader />
        <ContentLayout>
          <Outlet />
        </ContentLayout>
      </AntdLayout>
    </AntdLayout>
  );
};

export default Layout;
