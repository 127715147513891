import { useEffect } from 'react';
import socket, { listenToEvent } from './socketServices';

const useWebSocket = (event: string, callback: (data: any) => void) => {
  useEffect(() => {
    listenToEvent(event, callback);

    return () => {
      socket.off(event, callback);
    };
  }, [event, callback]);
};

export default useWebSocket;
