import { FC } from 'react';
import { Flex, Text } from '../../components';
import {
  BlueCircle,
  GreenCircle,
  PurpleCircle,
  RedCircle,
  YellowCircle,
} from '../../assets';
import styled from '@emotion/styled';

const Legent = styled(Flex)`
  margin-left: 16px;
`;

const InboxLegends: FC = () => {
  return (
    <Flex display="flex" flexWrap="wrap">
      <Legent display="flex" alignItems="center">
        <BlueCircle />
        <Text type="caption1-regular" ml="4px">
          New
        </Text>
      </Legent>
      <Legent display="flex" alignItems="center">
        <YellowCircle />
        <Text type="caption1-regular" ml="4px">
          Snoozed
        </Text>
      </Legent>
      <Legent display="flex" alignItems="center">
        <RedCircle />
        <Text type="caption1-regular" ml="4px">
          Overdue
        </Text>
      </Legent>
      <Legent display="flex" alignItems="center">
        <PurpleCircle />
        <Text type="caption1-regular" ml="4px">
          In Progress
        </Text>
      </Legent>
      <Legent display="flex" alignItems="center">
        <GreenCircle />
        <Text type="caption1-regular" ml="4px">
          Completed
        </Text>
      </Legent>
    </Flex>
  );
};

export default InboxLegends;
