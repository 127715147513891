export interface CustomTasks {
  name: string;
  status: string;
  completedAt: Date;
  cleaner: string;
  isCompleted: boolean;
  dailyStatus: any;
  schedule: string;
}

export interface AreaI {
  areaName: string;
  tasks: CustomTasks[];
}

export interface FixedRowsI {
  scheduleRow: string[];
  completedTimeRow: Date[];
  completionRow: string[];
}
export interface ReportData {
  colNames: string[];
  areas: AreaI[];
  fixedRows: FixedRowsI;
}

export enum DailyTaskStatus {
  COMPLETED = 'COMPLETED',
  NA = 'N/A',
  NOT_COMPLETED = 'NOT COMPLETED',
}

export enum DateFrequencyEnum {
  LAST30DAYS = 'LAST30DAYS',
  LAST14DAYS = 'LAST14DAYS',
  LAST7DAYS = 'LAST7DAYS',
}
