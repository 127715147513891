import { useMutation, useQuery } from 'react-query';
import { ApiError } from './_types';
import { http } from './_apiService';
import {
  CreateTaskI,
  PostRequestI,
  RequestI,
  SnoozeTaskI,
} from '../containers/inbox/interfaces/inbox.interface';
import dayjs from 'dayjs';
import { Message } from '../components/message/Message';

export const useGetRequests = () =>
  useQuery<RequestI[], ApiError>('getRequests', async () => {
    const response = await http.get('/requests');
    return response.data;
  });

export const usePostRequest = () => {
  return useMutation<string, ApiError, PostRequestI>(
    async (data: PostRequestI) => {
      const formData = new FormData();

      data.area && formData.append('areaId', data.area);
      data.item && formData.append('itemId', data.item);
      data.title && formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('type', data.type);

      const { images } = data;

      images?.forEach((image) => {
        formData.append('images', image);
      });

      const response = await http.post('/requests', formData);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Item added successfully!');
      },
    },
  );
};

export const useCompleteTodo = () => {
  return useMutation<string, ApiError, string>(
    async (todoId: string) => {
      const response = await http.patch(`/requests/todo/${todoId}/complete`);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Todo updated successfully!');
      },
    },
  );
};

export const useMarkAsRead = () => {
  return useMutation<string, ApiError, string>(async (requestId: string) => {
    const response = await http.patch(`/requests/mark-as-read/${requestId}`);
    return response.data;
  });
};

export const useScheduleTask = () => {
  return useMutation<string, ApiError, CreateTaskI>(
    async (data: CreateTaskI) => {
      const { scheduledDate, ...rest } = data;
      const requestObject = {
        ...rest,
        scheduledDate: dayjs(scheduledDate).format('YYYY-MM-DD'),
      };
      const response = await http.post('/requests/schedule', requestObject);
      return response.data;
    },
    {
      onSuccess: () => {
        Message.success('Item scheduled successfully!');
      },
    },
  );
};

export const useSnoozeTask = () => {
  return useMutation<string, ApiError, SnoozeTaskI>(
    async (data: SnoozeTaskI) => {
      const response = await http.post(`/requests/snooze`, data);
      return response.data;
    },
  );
};
