import { Menu_items } from '../configs/menuConfigs';
import { isBusinessOwner, isSuperAdmin } from './userRoleUtil';

export const useGetMenuItems = (userRoleId?: number) => {
  if (userRoleId && isBusinessOwner(userRoleId)) {
    return Menu_items.filter(
      (item) => item.displayName !== 'Labels' && item.displayName !== 'Tips',
    );
  } else if (userRoleId && isSuperAdmin(userRoleId)) {
    return Menu_items.filter(
      (item) =>
        item.displayName !== 'Reports' &&
        item.displayName !== 'Inbox' &&
        item.displayName !== 'Venues',
    );
  } else {
    return Menu_items;
  }
};
