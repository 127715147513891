import { MenuItemType, SettingsMenuI } from '../types/menu.types';

export const Menu_items: MenuItemType[] = [
  {
    displayName: 'Inbox',
    path: '/inbox',
    icon: 'inbox-selected.svg',
    iconUnselected: 'inbox.svg',
    className: 'menu.inbox',
  },
  {
    displayName: 'Venues',
    path: '/venues',
    icon: 'venues-selected.svg',
    iconUnselected: 'venues.svg',
    className: 'menu.items',
  },
  {
    displayName: 'Inventory',
    path: '/inventory',
    icon: 'items-selected.svg',
    iconUnselected: 'items.svg',
    className: 'menu.items',
  },

  {
    displayName: 'Tips',
    path: '/tips',
    icon: 'tips-selected.svg',
    iconUnselected: 'tips.svg',
    className: 'menu.items',
  },
  {
    displayName: 'Labels',
    path: '/labels',
    icon: 'translate-selected.svg',
    iconUnselected: 'translate.svg',
    className: 'menu.labels',
  },
  {
    displayName: 'Reports',
    path: '/reports',
    icon: 'reports-selected.svg',
    iconUnselected: 'reports-unselected.svg',
    className: 'menu.labels',
  },
];

export const Setting_Menu: SettingsMenuI[] = [
  {
    displayName: 'Profile',
    key: 'profile',
    path: '/settings/profile',
  },
  {
    displayName: 'Change Password ',
    key: 'resetPassword ',
    path: '/settings/reset-password',
  },
  {
    displayName: 'Users',
    key: 'users',
    path: '/settings/users',
  },
  {
    displayName: 'Venues & Business hours',
    key: 'venues&BusinessHours',
    path: '/settings/venues-business-hours',
  },
  {
    displayName: 'Category',
    key: 'category',
    path: '/settings/category',
  },
  {
    displayName: 'Code',
    key: 'code',
    path: '/settings/code',
  },
];
