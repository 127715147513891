import styled from '@emotion/styled';
import { TimePicker } from 'antd';
import { FC } from 'react';
import theme from '../../theme';
import { TimePickerProps } from 'antd/lib/time-picker';

const StyledTimePicker = styled(TimePicker)`
  border: 0.5px solid ${theme.gray400} !important;
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  box-shadow: none !important;
  width: 100%;
  &:focus {
    border-color: ${theme.gray400} !important;
  }

  &:hover {
    border-color: ${theme.gray600} !important;
    box-sizing: border-box;
    border-radius: 8px;
  }
`;
const TimePickerComp: FC<TimePickerProps> = ({ ...props }) => {
  return <StyledTimePicker {...props} />;
};

export default TimePickerComp;
