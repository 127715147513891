import React, { useState, useEffect, FC } from 'react';
import {
  Flex,
  MenuItem,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '../../components';
import {
  ChevronLeftTeal,
  ChevronRightTeal,
  CirclePlus,
  FortnightIcon,
  RedCross,
  WeekIcon,
} from '../../assets';
import styled from '@emotion/styled';
import theme from '../../theme';
import DatePickerComp from '../../components/datePicker/DatepickerComp';
import TableComponent from '../../components/table/TableComponent';
import dayjs, { Dayjs } from 'dayjs';
import {
  CancelReservationI,
  ReservationsAreaI,
} from './interfaces/venue.interface';
import { Dropdown, Menu } from 'antd';
import { DayEnum } from '../enums/Day.enum';

const CalendarHeader = styled(Flex)`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.gray300};
  flex-wrap: wrap;
  };
`;

const ReserveBtn = styled(PrimaryButton)`
  @media (max-width: 485px) {
    margin-top: 16px;
  }
`;

const DisabledCell = styled(Flex)`
  background: ${theme.gray200};
  min-height: 59px;
  min-width: 60px;
  cursor: not-allowed;
`;

const Container = styled(Flex)``;

const CalendarBody = styled(Flex)`
  overflow: scroll;
`;
const Chevrons = styled(Flex)`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const ChevronFlex = styled(Flex)`
  cursor: pointer;
`;

enum DateFilter {
  WEEKLY = 'WEEKLY',
  FORTNIGHT = 'FORTNIGH',
}
interface PropsI {
  openReserveModal: () => void;
  disableReserveBtn?: boolean;
  reservations?: ReservationsAreaI[];
  cancelReservation: (data: CancelReservationI) => void;
  setStartDate: (val: Dayjs) => void;
  setEndDate: (val: Dayjs) => void;
  setSelectedAreaInCalendar: (val: string) => void;
  openDays: string[];
}
const ReservationCalendar: FC<PropsI> = ({
  openReserveModal,
  reservations,
  disableReserveBtn,
  cancelReservation,
  setStartDate,
  setEndDate,
  setSelectedAreaInCalendar,
  openDays,
}) => {
  const [days, setDays] = useState<Dayjs[]>();
  const [dateFilter, setDateFilter] = useState<DateFilter>(
    DateFilter.FORTNIGHT,
  );
  const [date, setDate] = useState<Dayjs>(dayjs());

  useEffect(() => {
    if (date && dateFilter) {
      if (dateFilter === DateFilter.FORTNIGHT) {
        const startDate = date.startOf('week');
        const fortnightDays = [];

        for (let i = 0; i <= 13; i++) {
          const currentDay = startDate.add(i, 'day');
          fortnightDays.push(currentDay);
        }

        setDays(fortnightDays);
      } else if (dateFilter === DateFilter.WEEKLY) {
        const startDate = date.startOf('week');
        const weeklyDays = [];

        for (let i = 0; i <= 6; i++) {
          const currentDay = startDate.add(i, 'day');
          weeklyDays.push(currentDay);
        }

        setDays(weeklyDays);
      }
    }
  }, [date, dateFilter]);

  const oneWeekForward = () => {
    setDate(date.add(7, 'day'));
  };

  const oneWeekBack = () => {
    setDate(date.subtract(7, 'day'));
  };
  const columns = [
    {
      dataIndex: 'areaName',
      key: 'area',
      width: 350,
      title: (
        <>
          <Flex display="flex">
            <SecondaryButton
              mr={'16px'}
              size="middle"
              onClick={() => setDate(dayjs())}>
              Today
            </SecondaryButton>
            <DatePickerComp
              size="middle"
              forCalendar={true}
              onChange={(val: Dayjs) => setDate(val)}
            />
            <Chevrons>
              <ChevronFlex onClick={oneWeekBack}>
                <ChevronLeftTeal />
              </ChevronFlex>
              <ChevronFlex onClick={oneWeekForward}>
                <ChevronRightTeal />
              </ChevronFlex>
            </Chevrons>
          </Flex>
        </>
      ),
      render: (value: string) => {
        return <Flex p="18px 16px">{value}</Flex>;
      },
    },
    ...(days
      ? days.map((day) => ({
          title: (
            <Flex display="flex" alignItems="center" flexDirection="column">
              <Text type="caption1-regular" color={theme.gray800}>
                {day.format('MMM')}
              </Text>
              <Text type="subheading-bold">{day.format('DD')}</Text>
              <Text type="caption1-regular" color={theme.gray800}>
                {day.format('ddd')}
              </Text>
            </Flex>
          ),
          dataIndex: 'dates',
          key: day.format('YYYY-MM-DD'),
          width: 72,
          render: (reservations: string[], values: ReservationsAreaI) => {
            const isReserved = reservations.some(
              (val) =>
                day.format('YYYY-MM-DD') === dayjs(val).format('YYYY-MM-DD'),
            );

            const isOpenDay = openDays.includes(
              day.format('dddd').toUpperCase() as DayEnum,
            );

            return (
              <Flex
                key={day.format('YYYY-MM-DD')}
                background={isReserved ? theme.purple500 : theme.white}
                minHeight="59px"
                minWidth="60px"
                onClick={() => {
                  if (!isReserved && isOpenDay) {
                    setStartDate(day);
                    setEndDate(day);
                    setSelectedAreaInCalendar(values.areaId);
                    openReserveModal();
                  }
                }}>
                {isReserved && isOpenDay ? (
                  <Dropdown
                    dropdownRender={() => (
                      <Menu style={{ marginTop: '8px', marginLeft: '24px' }}>
                        <MenuItem
                          className="custom-dropdown-no-bg"
                          key="edit"
                          onClick={() => {
                            cancelReservation({
                              areaId: values.areaId,
                              date: day.format('YYYY-MM-DD'),
                            });
                          }}>
                          <Flex display="flex">
                            <RedCross />
                            <Text
                              ml="8px"
                              type="subheading-regular"
                              color={theme.red}>
                              Cancel reservation
                            </Text>
                          </Flex>
                        </MenuItem>
                      </Menu>
                    )}>
                    <Flex
                      background={theme.purple500}
                      minHeight="59px"
                      minWidth="60px"
                    />
                  </Dropdown>
                ) : !isOpenDay ? (
                  <DisabledCell />
                ) : (
                  <></>
                )}
              </Flex>
            );
          },
        }))
      : []),
  ];

  return (
    <Container>
      <CalendarHeader>
        <Flex display="flex">
          <SecondaryButton
            mr="16px"
            icon={<WeekIcon />}
            size="middle"
            style={
              dateFilter === DateFilter.WEEKLY
                ? { backgroundColor: theme.brandTeal20 }
                : {}
            }
            onClick={() => setDateFilter(DateFilter.WEEKLY)}>
            Week
          </SecondaryButton>
          <SecondaryButton
            icon={<FortnightIcon />}
            size="middle"
            style={
              dateFilter === DateFilter.FORTNIGHT
                ? { backgroundColor: theme.brandTeal20 }
                : {}
            }
            onClick={() => setDateFilter(DateFilter.FORTNIGHT)}>
            Fortnight
          </SecondaryButton>
        </Flex>
        <ReserveBtn
          size="middle"
          icon={<CirclePlus />}
          disabled={disableReserveBtn}
          onClick={openReserveModal}>
          Reserve
        </ReserveBtn>
      </CalendarHeader>

      <CalendarBody>
        <TableComponent
          columns={columns}
          dataSource={reservations}
          bordered={true}
          isCalendar={true}
          scroll={{ x: 'max-content' }}
          height="100%"
          emptyInfoText="You don't have any bookable areas yet."
        />
      </CalendarBody>
    </Container>
  );
};

export default ReservationCalendar;
