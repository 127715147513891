import { FC, useState } from 'react';
import { MainCard } from '../../components/MainCard';
import { Row, Col, Menu } from 'antd';
import { Flex } from '../../components';
import { DisabledPlusCircle, CirclePlus } from '../../assets';
import { PrimaryButton } from '../../components';
import { SearchInput } from '../../components/inputs/SearchInput';
import { AreaTypeFilterEnum } from '../../types/equipmentsItems.types';
import { UserRoleIdEnum } from '../../types/user.types';
import { isBusinessOwner } from '../../utils/userRoleUtil';
import AreaSelector from '../item/AreaSelector';
import VenuesSelector from '../item/VenuesSelector';
import styled from '@emotion/styled';
import { useAuth } from '../../providers/auth-context';
import { useNavigate, useParams } from 'react-router-dom';
import { convertToUpperAndReplaceSpaces } from '../../utils/textUtil';
import { getPascalCaseUtil } from '../../utils/caseUtil';

import ItemList from '../item/ItemList';
import CleaningProducts from '../cleaning-products/CleaningProducts';
import CategorySelector from '../cleaning-products/CategorySelector';
import { CleaningProductTypeEnum } from '../../types/cleaningProducts.types';
import ButtonDropdown from '../../components/dropdown/ButtonDropdown';
import theme from '../../theme';
import { TabsEnum } from './inventory.enum';
import { SegmentedComp } from '../../components/tabs/Segmented';
import { useGetVenues } from '../../api/venueHooks';

type param = {
  venueIdParam: string;
};

const SearchDiv = styled(Flex)`
  @media screen and (max-width: 760px) {
    margin: 8px 0px;
  }
`;
const Card = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media screen and (max-width: 760px) {
    display: inherit;
  }
`;

const MenuItem = styled(Menu.Item)`
  border-top: 0.5px solid ${theme.gray300};
  .ant-dropdown-menu-title-content {
    padding: 7px 54px 7px 4px;
    font-family: SF Pro Display;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Inventory: FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [venueId, setVenueId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filter, setFilter] = useState<AreaTypeFilterEnum>(
    AreaTypeFilterEnum.ALL_AREA,
  );
  const [filterCategory, setFilterCategory] = useState<CleaningProductTypeEnum>(
    CleaningProductTypeEnum.ALL,
  );
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.ITEMS);
  const [isEquipment, setIsEquipment] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const { data: venues, isLoading: isLoadingVenues } = useGetVenues();

  const { venueIdParam } = useParams<param>();
  return (
    <MainCard>
      <Flex m="0px 24px 16px">
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <SegmentedComp
              value={getPascalCaseUtil(tab)}
              defaultValue="Items"
              onChange={(val) =>
                setTab(
                  convertToUpperAndReplaceSpaces(val.toString()) as TabsEnum,
                )
              }
              options={['Items', 'Cleaning Products']}
            />
            {/* <H type="title3Bold">TabHere</H> */}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Card>
              <SearchDiv>
                <SearchInput
                  size="small"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </SearchDiv>

              {(currentUser?.role.id == UserRoleIdEnum.BUSINESS_OWNER ||
                currentUser?.role.id == UserRoleIdEnum.ADMIN) && (
                <VenuesSelector
                  defaultVenueId={venueIdParam}
                  data={venues ? venues : []}
                  onChange={(value: string | null) => setVenueId(value)}
                />
              )}
              <Flex display="flex">
                {tab === TabsEnum.ITEMS ? (
                  <AreaSelector
                    onChange={(value: AreaTypeFilterEnum) => {
                      setFilter(value);
                    }}
                  />
                ) : (
                  <CategorySelector
                    onChange={(value: string) => {
                      setFilterCategory(value as CleaningProductTypeEnum);
                    }}
                  />
                )}
                {tab === TabsEnum.ITEMS ? (
                  <PrimaryButton
                    onClick={() => navigate('/inventory/add-items')}
                    size="middle"
                    icon={
                      currentUser &&
                      isBusinessOwner(currentUser?.role.id) &&
                      venueId === null ? (
                        <DisabledPlusCircle />
                      ) : (
                        <CirclePlus />
                      )
                    }
                    disabled={
                      currentUser &&
                      isBusinessOwner(currentUser?.role.id) &&
                      venueId === null
                        ? true
                        : false
                    }>
                    Add Item
                  </PrimaryButton>
                ) : (
                  <ButtonDropdown
                    label={'Add Item'}
                    dropdownRender={() => (
                      <Menu>
                        <MenuItem
                          key="Equipment"
                          onClick={() => {
                            setIsEquipment(true);
                            setVisible(true);
                          }}>
                          Add Equipment
                        </MenuItem>
                        <MenuItem
                          key="Chemical"
                          onClick={() => {
                            setIsEquipment(false);
                            setVisible(true);
                          }}>
                          Add Chemical
                        </MenuItem>
                      </Menu>
                    )}
                    mr="16px"
                  />
                )}
              </Flex>
            </Card>
          </Col>
        </Row>
      </Flex>

      {tab === TabsEnum.ITEMS ? (
        <ItemList
          venueId={venueId ? venueId : null}
          searchTerm={searchTerm}
          area={filter}
          isVenueLoading={isLoadingVenues}
        />
      ) : (
        <CleaningProducts
          visible={visible}
          setVisible={setVisible}
          isEquipment={isEquipment}
          setIsEquipment={setIsEquipment}
          searchTerm={searchTerm}
          category={filterCategory}
        />
      )}
    </MainCard>
  );
};

export default Inventory;
