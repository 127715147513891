import styled from '@emotion/styled';
import { Col, Form, Row, Select, Spin, Upload } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { useGetCategories } from '../../api/categoryHooks';
import { useGetCodes } from '../../api/codeHook';
import {
  FileUpload,
  Nederlanden,
  UnitedStates,
  emptyImage,
} from '../../assets';
import {
  AlertMessage,
  Flex,
  FormContainer,
  H,
  Image,
  Input,
  PrimaryButton,
  SecondaryButton,
  SelectComp,
  Text,
} from '../../components';
import { Message } from '../../components/message/Message';
import { getCodeTag } from '../../components/tags/CodeTag';
import { useAuth } from '../../providers/auth-context';
import theme from '../../theme';
import {
  AreaTypeEnum,
  HandleContentChange,
  ScheduleContentKeys,
  ScheduleDetailsI,
} from '../../types/equipmentsItems.types';
import { getPascalCaseUtil } from '../../utils/caseUtil';
import { ScheduleLayout } from './ScheduleLayout';
import { scrollToFirstErrorField, isFormDirty } from '../../utils/utils';
import { BlockRouteLeaveModal } from '../../components/modals/BlockRouteLeaveModal';
import { useCreateTip, useGetTip, useUpdateTip } from '../../api/tipHooks';
import {
  AddTipsScheduleI,
  PostTipsI,
  TipsI,
  TipsInformationI,
} from '../../types/tips.types';

type param = {
  venueId: string;
  id: string;
  duplicateId: string;
};

const CusTopPanel = styled(Flex)`
  border: 1px solid ${theme.gray400};
  border-radius: 8px;
  background: ${theme.white};
  padding: 12px;
  margin-bottom: 16px;
  height: 82px;
  display: flex;
  align-items: center;
`;

const CusSidePanel = styled(Flex)`
  border: 1px solid ${theme.gray400};
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${theme.white};
  margin-right: 16px;
  padding: 24px;
  height: 600px;

  @media (max-width: 995px) {
    margin-right: 8px !important;
    margin-bottom: 8px;
    height: 400px;
  }

  @media (max-width: 1023px) {
    margin-right: 8px !important;
    margin-bottom: 8px;
  }
`;

const Typography = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
`;

const UploadIcon = styled(Image)`
  border-radius: 8px;
  width: 80px;
  height: 80px;
`;

const Footer = styled(Flex)`
  position: fixed;
  width: 100%;
  background: ${theme.white};
  display: flex;
  flex-direction: row-reverse;
  height: 72px;
  bottom: 0px;
  z-index: 1;
  padding: 16px 24px;
  margin-left: -24px;
  box-shadow: 0px 0px 8px ${theme.gray400};
`;

const StyledBody = styled(Flex)`
  height: 0px !important;

  @media (min-width: 1023px) {
    height: 750px !important;
  }
  @media (max-width: 995px) {
    height: 1200px !important;
  }
`;

const AddItemsMainLayout: FC = () => {
  const initialState: ScheduleDetailsI = {
    schedule: '',
    title: {
      en: '',
      nl: '',
    },
    cleaningInstructions: {
      en: '',
      nl: '',
    },
    cleaningProducts: [],
    precautions: {
      en: '',
      nl: '',
    },
    tips: {
      en: '',
      nl: '',
    },
  };

  const [form] = Form.useForm();
  const { currentUser, isLoading } = useAuth();
  const [uploadIcon, setUploadIcon] = useState<File | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingItem, setEditingItem] = useState<TipsI>();
  const [dirtyForm, setDirtyForm] = useState<boolean>(false);

  const [information, setInformation] =
    useState<TipsInformationI>(initialState);

  const navigate = useNavigate();

  const { id, duplicateId } = useParams<param>();

  const {
    data: tipData,
    isLoading: isLoadingTipData,
    mutate: getTipData,
  } = useGetTip();

  const {
    mutate: createTip,
    isLoading: isCreateTipLoading,
    isSuccess: isCreateTipSuccess,
    error: errorCreateTip,
  } = useCreateTip();

  const {
    mutate: updateTip,
    isLoading: isUpdateTipLoading,
    isSuccess: isUpdateTipSuccess,
    error: errorUpdateTip,
  } = useUpdateTip();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }): boolean =>
      dirtyForm &&
      currentLocation.pathname !== nextLocation.pathname &&
      nextLocation.pathname !== '/login',
  );

  useEffect(() => {
    if (isCreateTipSuccess || isUpdateTipSuccess) {
      setDirtyForm(false);
      navigate('/tips');
    }
  }, [isCreateTipSuccess, isUpdateTipSuccess]);

  useEffect(() => {
    errorUpdateTip && Message.error(errorUpdateTip.msg);
    errorCreateTip && Message.error(errorCreateTip.msg);
  }, [errorUpdateTip, errorCreateTip]);

  useEffect(() => {
    if (id) {
      setIsEditing(true);
      getTipData(id);
    } else if (duplicateId) {
      setIsEditing(false);
      getTipData(duplicateId);
    } else {
      setIsEditing(false);
      setEditingItem(undefined);
    }
  }, [id, currentUser]);

  useEffect(() => {
    setEditingItem(tipData);

    if (duplicateId) {
      form.setFieldsValue({
        titleEn: undefined,
        titleNl: undefined,
        area: tipData?.area,
        code: tipData?.colorCode.id,
      });
    } else {
      form.setFieldsValue({
        titleEn: tipData?.name.en,
        titleNl: tipData?.name.nl,
        area: tipData?.area,
        code: tipData?.colorCode.id,
      });
    }

    const processScheduleDetails = (input: TipsInformationI) => {
      return {
        ...input,
        cleaningProducts: input?.cleaningProducts?.map((product) =>
          typeof product === 'object' && 'id' in product ? product.id : product,
        ) as string[],
      };
    };

    if (tipData) {
      setInformation(processScheduleDetails(tipData.information));
    }
  }, [tipData, currentUser]);

  const { data: categories, refetch: refetchCategories } = useGetCategories();
  const { data: colorCodes, refetch: refetchColorCodes } = useGetCodes();

  const handleReturnValue = (
    data: HandleContentChange,
    prevState: TipsInformationI,
  ) => {
    const updatedTitle = data?.title;
    const updatedLanguage = data?.language;
    if (data.title && data.language && !data.cleaningProducts)
      return {
        ...prevState,
        [updatedTitle as string]: {
          ...prevState[data.title as ScheduleContentKeys],
          [updatedLanguage as string]: data.content,
        },
      };
    else
      return {
        ...prevState,
        cleaningProducts: data.cleaningProducts || prevState.cleaningProducts,
      };
  };

  const handleContentChange = (data: HandleContentChange) => {
    setDirtyForm(isFormDirty(data));
    setInformation((prevState: TipsInformationI) =>
      handleReturnValue(data, prevState),
    );
  };

  const onFinish = (formValues: AddTipsScheduleI) => {
    const data: PostTipsI = {
      name: {
        en: formValues.titleEn,
        nl: formValues.titleNl,
      },
      area: formValues.area,
      colorCode: formValues.code,
      scheduleDetails: information,
      icon: uploadIcon,
    };

    isEditing ? updateTip({ ...data, id: id }) : createTip(data);
  };

  useEffect(() => {
    refetchCategories();
    refetchColorCodes();
  }, []);

  const beforeUpload = (file: File) => {
    const isSvg = file.type === 'image/svg+xml';
    if (!isSvg) {
      Message.error('You can only upload SVG files!');
    }

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      Message.error('Image must be smaller than 5MB!');
    }

    if (isSvg && isLt5M) {
      setUploadIcon(file);
    }
    return isSvg && isLt5M;
  };

  const checkForFormDirty = (values: { [key: string]: string }) => {
    if (isEditing) {
      const originalData = editingItem;

      if (originalData) {
        for (const key in values) {
          const currentValue = values[key];
          let originalValue = originalData[key as keyof TipsI];

          if (key === 'titleEn') {
            originalValue = originalData.name.en;
          } else if (key === 'titleNl') {
            originalValue = originalData.name.nl;
          }
          if (originalValue === currentValue && !isFormDirty) {
            setDirtyForm(false);
          } else if (originalValue !== currentValue) {
            setDirtyForm(true);
          }
        }
      }
    } else {
      const formValues = form.getFieldsValue();
      const isFormDirty = Object.values(formValues).some((value) => {
        return value !== '' && value !== null && value !== undefined;
      });
      setDirtyForm(isFormDirty);
    }
  };

  return (
    <>
      <Spin spinning={isLoading || isLoadingTipData}>
        {duplicateId && (
          <Flex mb="16px">
            <AlertMessage
              text="Please use a different icon for duplicate items to distinguish them from other items."
              textColor={theme.black}
            />
          </Flex>
        )}
        <StyledBody>
          <FormContainer
            layout="vertical"
            form={form}
            validateTrigger="onBlur"
            onValuesChange={checkForFormDirty}
            onFinishFailed={scrollToFirstErrorField}
            onFinish={(v) => onFinish(v)}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <CusTopPanel>
                  <H type="headlineBold">Tip Name:</H>
                  <Flex m="20px 20px 0px">
                    <Form.Item
                      name="titleEn"
                      rules={[
                        {
                          required: true,
                          message: 'Enter item english name',
                        },
                      ]}>
                      <Input
                        placeholder="Enter item english name"
                        prefix={
                          <Image src={UnitedStates} borderRadius={'24px'} />
                        }
                      />
                    </Form.Item>
                  </Flex>
                  <Flex m="20px 20px 0px">
                    <Form.Item
                      name="titleNl"
                      rules={[
                        {
                          required: true,
                          message: 'Enter item dutch name',
                        },
                      ]}>
                      <Input
                        placeholder="Enter item dutch name"
                        prefix={
                          <Image src={Nederlanden} borderRadius={'24px'} />
                        }
                      />
                    </Form.Item>
                  </Flex>
                </CusTopPanel>
              </Col>
            </Row>

            <Row style={{ marginBottom: '25px' }}>
              <Col xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
                <CusSidePanel>
                  <Row gutter={[16, 16]}>
                    <Col span={4}>
                      <UploadIcon
                        src={
                          uploadIcon
                            ? URL.createObjectURL(uploadIcon)
                            : isEditing && tipData?.icon
                              ? tipData.icon
                              : emptyImage
                        }
                      />
                    </Col>

                    <Col span={20}>
                      <Flex mb={'48px'} ml="42px">
                        <Upload
                          action={''}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          accept="image/svg+xml">
                          <Flex display="flex" flexDirection="row" mt="24px">
                            <Image src={FileUpload} cursor="pointer" />
                            <Typography
                              cursor="pointer"
                              ml="8px"
                              type={'subheading-bold'}
                              color={theme.brandDarkTeal}>
                              Upload Icon
                            </Typography>
                          </Flex>
                        </Upload>

                        <Text type={'subheading-regular'} color={theme.gray800}>
                          Supported file type: (SVG)
                        </Text>
                      </Flex>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Area Type *"
                        name="area"
                        rules={[
                          {
                            required: true,
                            message: 'Select area type',
                          },
                        ]}>
                        <SelectComp placeholder="Select location">
                          {Object.values(AreaTypeEnum).map((area) => (
                            <Select.Option key={area} value={area}>
                              {getPascalCaseUtil(area)}
                            </Select.Option>
                          ))}
                        </SelectComp>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="Code *"
                        name="code"
                        rules={[
                          {
                            required: true,
                            message: 'Select code',
                          },
                        ]}>
                        <SelectComp placeholder="Select code">
                          {colorCodes &&
                            colorCodes.map((code) => (
                              <Select.Option key={code.id} value={code.id}>
                                {getCodeTag(code.codeName, code.color)}
                              </Select.Option>
                            ))}
                        </SelectComp>
                      </Form.Item>
                    </Col>
                  </Row>
                </CusSidePanel>
              </Col>

              <Col xs={24} sm={24} md={24} lg={17} xl={17} xxl={17}>
                <Flex
                  height={'600px'}
                  paddingBottom={'250px'}
                  style={{
                    background: 'transparent',
                    border: `0.5px solid ${theme.gray200}`,
                  }}>
                  <Flex
                    style={{
                      padding: '16px',
                      marginBottom: 0,
                      background: `${theme.white}`,
                      borderRadius: '8px',
                      border: `1px solid ${theme.gray400}`,
                      height: `600px`,
                      overflow: 'auto',
                    }}>
                    <ScheduleLayout
                      handleContentChange={handleContentChange}
                      initialValue={editingItem?.information}
                      label={'DAILY'}
                    />
                  </Flex>
                </Flex>
              </Col>
            </Row>
          </FormContainer>
        </StyledBody>
      </Spin>
      <Footer>
        <PrimaryButton
          onClick={() => {
            setDirtyForm(false);
            form.submit();
          }}
          loading={isCreateTipLoading || isUpdateTipLoading}>
          Save
        </PrimaryButton>
        <SecondaryButton
          mr={'24px'}
          onClick={() => {
            navigate('/tips');
          }}>
          Cancel
        </SecondaryButton>
      </Footer>
      {blocker.state === 'blocked' && (
        <BlockRouteLeaveModal
          modalVisible={blocker.state === 'blocked'}
          handleCancel={() => blocker.reset()}
          handleProceed={() => blocker.proceed()}
        />
      )}
    </>
  );
};
export default AddItemsMainLayout;
