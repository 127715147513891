import { FC } from 'react';
import { Flex, Image, PrimaryButton, Text } from '../../components';
import theme from '../../theme';
import { greenInbox, GreenPlus } from '../../assets';
import styled from '@emotion/styled';

const AddNewBtn = styled(PrimaryButton)`
  background-color: ${theme.white};
  border: none;
  box-shadow: none;
  color: ${theme.brandTeal};
  padding: 0px 8px;
  margin-top: 8px;

  &:hover {
    background-color: ${theme.white} !important;
    color: ${theme.brandTeal} !important;
    border: 1px solid ${theme.brandTeal} !important;
    cursor: pointer;
  }
`;
interface Props {
  addNewItem: (val: boolean) => void;
}
const EmptyListView: FC<Props> = ({ addNewItem }) => {
  return (
    <Flex
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      flexDirection="column">
      <Flex
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        width="245px">
        <Image src={greenInbox} width="40px" height="32px" mb="8px" />
        <Text type="body-bold">Nothing on your to-do list!</Text>
        <Text type="subheading-regular" color={theme.gray800}>
          Got something on your mind? Add a new to-do to get started!
        </Text>
        <AddNewBtn onClick={() => addNewItem(true)}>
          <GreenPlus style={{ marginRight: '8px' }} />
          <Text type="subheading-regular">Add New</Text>
        </AddNewBtn>
      </Flex>
    </Flex>
  );
};

export default EmptyListView;
