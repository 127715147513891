import { FC, useState } from 'react';
import ModalComponent from '../../components/modals/ModalComponent';
import { Form } from 'antd';
import { Flex, FormContainer, Input, Text } from '../../components';
import CustomTextArea from '../../components/TextArea/CustomTextArea';
import { PostRequestI } from './interfaces/inbox.interface';
import { InboxType } from '../enums/Inbox.enum';
import DragAndDropFileUpload from '../../components/fileUpload/DragAndDropFileUpload';
import ImagePreview from '../../components/image/ImagePreview';

interface PropsI {
  onCancel: () => void;
  visible: boolean;
  postTodo: (data: PostRequestI) => void;
  isLoading: boolean;
}

const CreateTodoModal: FC<PropsI> = ({
  onCancel,
  visible,
  postTodo,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const handleSetFormImage = (file: File) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages, file].slice(0, 3);
      return updatedImages;
    });

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviews((prevPreviews) => {
        const updatedPreviews = [
          ...prevPreviews,
          reader.result as string,
        ].slice(0, 3);
        return updatedPreviews;
      });
    };
    reader.readAsDataURL(file);
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, i) => i !== index),
    );
  };

  const handleFinish = () => {
    const data: PostRequestI = {
      ...form.getFieldsValue(),
      images: images,
      type: InboxType.TODO,
    };
    postTodo(data);
  };

  return (
    <ModalComponent
      onCancel={onCancel}
      onSubmit={() => form && form.submit()}
      header={'New to-do'}
      hideCancel={false}
      primaryBtnLabel="Add"
      disabled={isLoading}
      loadingBtn={isLoading}
      visible={visible}>
      <FormContainer
        layout="vertical"
        form={form}
        validateTrigger="onBlur"
        style={{ overflow: 'auto' }}
        onFinish={handleFinish}>
        <Form.Item
          label="Title *"
          name="title"
          rules={[{ required: true, message: 'Enter the title' }]}>
          <Input placeholder="Enter the title" />
        </Form.Item>

        <Form.Item
          label="Description *"
          name="description"
          rules={[{ required: true, message: 'Enter the description' }]}>
          <CustomTextArea placeholder="Enter the description" />
        </Form.Item>

        <Text type="subheading-regular" mb="8px">
          Images (Optional)
        </Text>
        <Flex display="flex" flexWrap="wrap" mb="16px">
          {imagePreviews.map((imageLink, index) => (
            <Flex
              key={index}
              display="flex"
              flexWrap="wrap"
              height="140px"
              maxHeight="140px"
              maxWidth="140px"
              width="140px">
              <ImagePreview
                src={imageLink}
                m="0px 16px 16px 0px"
                maxHeight="150px"
                maxWidth="150px"
                showCloseIcon={true}
                onClose={() => handleRemoveImage(index)}
              />
            </Flex>
          ))}
          {imagePreviews.length < 3 ? (
            <Form.Item name="image1" style={{ marginRight: '16px' }}>
              <DragAndDropFileUpload
                accept={['image/jpeg', 'image/png']}
                minSize={1024}
                maxSize={5242880}
                setFormImage={handleSetFormImage}
                showPreview={false}
                multiple={false}
              />
            </Form.Item>
          ) : (
            <></>
          )}
        </Flex>
      </FormContainer>
    </ModalComponent>
  );
};

export default CreateTodoModal;
