import styled from '@emotion/styled';
import {
  Col,
  Dropdown,
  Menu,
  Row,
  Spin,
  TableColumnsType,
  Tooltip,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CirclePlus, MenuThreeDots, Sort } from '../../assets';
import { Flex, H, Image, PrimaryButton, Text } from '../../components';
import { MainCard } from '../../components/MainCard';
import { SearchInput } from '../../components/inputs/SearchInput';
import { Message } from '../../components/message/Message';
import { DeleteModal } from '../../components/modals/DeleteModal';
import TableComponent from '../../components/table/TableComponent';
import { getCodeTag } from '../../components/tags/CodeTag';
import { getStatusTag } from '../../components/tags/StatusTag';
import { useAuth } from '../../providers/auth-context';
import theme from '../../theme';
import { ManageByEnum } from '../../types/cleaningProducts.types';
import {
  AreaTypeFilterEnum,
  StatusEnum,
} from '../../types/equipmentsItems.types';
import { getPascalCaseUtil } from '../../utils/caseUtil';
import { useWindowSize } from '../../utils/screenSize';
import { tableSorter } from '../../utils/tableSorter';
import { addDotsForLongText } from '../../utils/textUtil';
import { isBusinessOwner, isSuperAdmin } from '../../utils/userRoleUtil';
import { useGetTipList, useToggleTipStatus } from '../../api/tipHooks';
import { TipsI } from '../../types/tips.types';
import AreaSelector from '../item/AreaSelector';

const MenuItem = styled(Menu.Item)`
  border-top: 0.5px solid ${theme.gray300};
  .ant-dropdown-menu-title-content {
    padding: 7px 54px 7px 4px;
    font-family: SF Pro Display;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const PreviewImage = styled(Image)`
  .ant-image .ant-image-img {
    border-radius: 4px;
  }
`;

const Card = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media screen and (max-width: 760px) {
    display: inherit;
  }
`;

const SearchDiv = styled(Flex)`
  @media screen and (max-width: 760px) {
    margin: 8px 0px;
  }
`;

const TipList: FC = () => {
  const { isTablet, isDesktop } = useWindowSize();
  const { currentUser, isLoading } = useAuth();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filter, setFilter] = useState<AreaTypeFilterEnum>(
    AreaTypeFilterEnum.ALL_AREA,
  );

  const {
    data: tips,
    refetch: getTips,
    isLoading: isLoadingTips,
  } = useGetTipList();

  const {
    mutate: toggleTipStatus,
    isLoading: isLoadingToggleTipStatus,
    data: toggleTipStatusData,
    error: errorToggleTipStatus,
  } = useToggleTipStatus();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filterProducts = (tip: TipsI, filter: string, searchTerm: string) => {
    const searchTermLower = searchTerm.toLowerCase();
    const nameMatch =
      tip.name &&
      tip.name.en &&
      tip.name.en.toLowerCase().includes(searchTermLower);
    const areaMatch =
      tip.area && tip.area.toLowerCase().includes(searchTermLower);
    const codeMatch =
      tip.colorCode &&
      tip.colorCode.name &&
      tip.colorCode.name.toLowerCase().includes(searchTermLower);

    switch (filter) {
      case 'BUILDING':
        return (nameMatch || codeMatch || areaMatch) && tip.area === 'BUILDING';
      case 'KITCHEN':
        return (nameMatch || codeMatch || areaMatch) && tip.area === 'KITCHEN';
      case 'SANITARY':
        return (nameMatch || codeMatch || areaMatch) && tip.area === 'SANITARY';
      default:
        return nameMatch || codeMatch || areaMatch;
    }
  };

  const filteredData = (data: TipsI[]) => {
    return data?.filter((tip) => filterProducts(tip, filter, searchTerm));
  };

  const handleEdit = (id: string) => {
    navigate(`/tips/edit-tip/${id}`);
  };

  const handleDuplicateEdit = (id: string) => {
    navigate(`/tips/duplicate-tip/${id}`);
  };

  const columns: TableColumnsType<TipsI> = [
    {
      title: (
        <Flex display="flex" alignItems="center">
          Tip Name <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'name',
      width: 250,
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.name.en, b.name.en),
      },
      render: (value, data) => (
        <Flex display="flex">
          <PreviewImage width={32} src={data.icon} />

          <Text
            type="subheading-regular"
            color={theme.gray800}
            ml="6px"
            mt="6px">
            {value.en}
          </Text>
        </Flex>
      ),
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Status <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'status',
      width: 150,
      render: (value) => getStatusTag(value),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.status, b.status),
      },
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Code <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'colorCode',
      width: 220,
      render: (value) =>
        value.name ? (
          value.name.length > 23 ? (
            <Tooltip title={value.name}>
              {getCodeTag(addDotsForLongText(value.name, 23), value.code)}
            </Tooltip>
          ) : (
            getCodeTag(value.name, value.code)
          )
        ) : (
          '-'
        ),
      sorter: {
        compare: (a, b) =>
          tableSorter.defaultSort(a.colorCode.code, b.colorCode.code),
      },
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Area Type
          <Sort style={{ marginLeft: '8px' }} />
        </Flex>
      ),
      dataIndex: 'area',
      width: 150,
      render: (value) => (value ? getPascalCaseUtil(value) : '-'),
      sorter: {
        compare: (a, b) => tableSorter.defaultSort(a.area, b.area),
      },
    },
    {
      title: (
        <Flex display="flex" alignItems="center">
          Action
        </Flex>
      ),
      dataIndex: 'id',
      width: 110,
      fixed: 'right',
      render: (value, data: TipsI) => {
        return (
          <>
            <Dropdown
              dropdownRender={() => (
                <Menu>
                  {currentUser &&
                  isBusinessOwner(currentUser?.role.id) &&
                  data.type === ManageByEnum.CUSTOM &&
                  data.status === StatusEnum.ACTIVE ? (
                    <MenuItem key="edit" onClick={() => handleEdit(data.id)}>
                      Edit
                    </MenuItem>
                  ) : (
                    currentUser &&
                    isSuperAdmin(currentUser?.role.id) &&
                    data.status === StatusEnum.ACTIVE && (
                      <MenuItem key="edit" onClick={() => handleEdit(data.id)}>
                        Edit
                      </MenuItem>
                    )
                  )}
                  {data.status === StatusEnum.ACTIVE && (
                    <MenuItem
                      key="duplicate_&_edit"
                      onClick={() => handleDuplicateEdit(data.id)}>
                      Duplicate & Edit
                    </MenuItem>
                  )}
                  {data.status === StatusEnum.ACTIVE ? (
                    <MenuItem
                      key="archive"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setItemId(value);
                      }}>
                      Archive
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key="archive"
                      onClick={() => {
                        toggleTipStatus(value);
                      }}>
                      Active
                    </MenuItem>
                  )}
                </Menu>
              )}>
              <img src={MenuThreeDots} />
            </Dropdown>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (currentUser && isSuperAdmin(currentUser.role.id)) {
      getTips();
    }
  }, [currentUser]);

  useEffect(() => {
    errorToggleTipStatus && Message.error(errorToggleTipStatus.msg);
  }, [errorToggleTipStatus]);

  useEffect(() => {
    if (toggleTipStatusData && !errorToggleTipStatus) {
      Message.success(toggleTipStatusData);
      getTips();
    }
  }, [toggleTipStatusData]);

  return (
    <Spin spinning={isLoadingTips || isLoading || isLoadingToggleTipStatus}>
      <MainCard>
        <Flex m="0px 24px 16px">
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <H type="title3Bold">Tips</H>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card>
                <SearchDiv>
                  <SearchInput
                    size="small"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e)}
                  />
                </SearchDiv>

                <Flex display="flex">
                  <AreaSelector
                    onChange={(value: AreaTypeFilterEnum) => {
                      setFilter(value);
                    }}
                  />
                  <PrimaryButton
                    onClick={() => navigate('/tips/add-tips')}
                    size="middle"
                    icon={<CirclePlus />}>
                    Add Tip
                  </PrimaryButton>
                </Flex>
              </Card>
            </Col>
          </Row>
        </Flex>

        <Flex pb="20px">
          <TableComponent
            loading={isLoading}
            columns={columns}
            dataSource={tips ? filteredData(tips) : undefined}
            scroll={{
              x: isTablet ? 1250 : isDesktop ? 1075 : 1075,
              y: window.innerHeight - 200,
            }}
          />
        </Flex>
        {showDeleteModal && itemId !== null && (
          <DeleteModal
            visible={showDeleteModal}
            onClickCancel={() => {
              toggleTipStatus(itemId);
            }}
            content={`Are you sure you want to Archive?`}
            title="Confirm Archive"
            handleCancel={() => {
              setShowDeleteModal(false);
              setItemId(itemId);
            }}
            buttonLabel="Remove"
          />
        )}
      </MainCard>
    </Spin>
  );
};

export default TipList;
