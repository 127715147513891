import { FC } from 'react';
import { InboxStatus, InboxType } from '../enums/Inbox.enum';
import { Flex, Text } from '../../components';
import styled from '@emotion/styled';
import theme from '../../theme';
import dayjs from 'dayjs';
import { addDotsForLongText } from '../../utils/textUtil';
import InboxIcon from './InboxIcon';
import { CyanCircle } from '../../assets';
import { Col, Tooltip } from 'antd';
import { isHaveThreeDots } from '../../utils/threeDotPopoverUtil';

interface PropsI {
  type: InboxType;
  status: InboxStatus;
  date: string;
  description: string;
  selected: boolean;
  isRead: boolean;
}

const Body = styled(Flex)`
  margin-left: 8px;
`;
interface ContainerProps {
  selected: boolean;
  status: InboxStatus;
}
const statusColors = {
  NEW: theme.blue,
  IN_PROGRESS: theme.purple500,
  COMPLETED: theme.green,
  SCHEDULED: theme.purple500,
  SNOOZED: theme.yellow,
  OVERDUE: theme.red,
};

const Container = styled(Flex)<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px;
  border-left: 4px solid ${(props) => statusColors[props.status]};
  ${(props) => props.selected && `background-color: ${theme.gray200};`};
`;

const InboxItemCard: FC<PropsI> = ({
  type,
  status,
  date,
  description,
  selected,
  isRead,
}) => {
  return (
    <Flex borderBottom={`1px solid ${theme.gray300}`} height="72px">
      <Container p={'16px 24px'} selected={selected} status={status}>
        <Col xs={16} sm={16} md={16} lg={16} xl={18}>
          <Flex display="flex" alignItems="center">
            <InboxIcon type={type} alwaysMargin={true} />
            <Body>
              <Tooltip
                placement="right"
                title={
                  isHaveThreeDots(description, 80) ? (
                    <span style={{ color: theme.black }}>{description}</span>
                  ) : (
                    ''
                  )
                }
                color={theme.white}>
                {}
                <Text type="subheading-regular">
                  {addDotsForLongText(description, 80)}
                </Text>
              </Tooltip>
            </Body>
          </Flex>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} xl={6}>
          <Flex textAlign="right">
            <Flex display="flex" justifyContent="flex-end" alignItems="center">
              {!isRead && <CyanCircle style={{ marginRight: '8px' }} />}
              <Text type="footnote-regular">{dayjs(date).format('HH:mm')}</Text>
            </Flex>
            <Text type="footnote-regular" mt="4px">
              {dayjs(date).format('YYYY-MM-DD')}
            </Text>
          </Flex>
        </Col>
      </Container>
    </Flex>
  );
};

export default InboxItemCard;
