import { FC } from 'react';
import { Flex } from '../flex/Flex';
import { Dropdown, Menu } from 'antd';
import { MenuItem } from '../menu/MenuItem';
import { SecondaryButton } from '../buttons/SecondaryButton';
import { GreenClock } from '../../assets';
import dayjs, { Dayjs } from 'dayjs';
import { SnoozeTaskI } from '../../containers/inbox/interfaces/inbox.interface';
import moment from 'moment';

interface Props {
  requestId: string;
  handleSnooze: (val: SnoozeTaskI) => void;
  showModal: (val: boolean) => void;
}

const SnoozeButton: FC<Props> = ({ handleSnooze, showModal, requestId }) => {
  const snoozeTask = (snoozeTime: Dayjs) => {
    const dateObject = snoozeTime.toDate();
    const utcSnoozeTime = moment(dateObject).utc().format();

    const requestObj: SnoozeTaskI = {
      requestId: requestId,
      snoozedDate: utcSnoozeTime,
    };
    handleSnooze(requestObj);
  };
  return (
    <Flex>
      <Dropdown
        dropdownRender={() => (
          <Menu>
            <MenuItem
              key="2hours"
              onClick={() => snoozeTask(dayjs().add(2, 'hour'))}>
              For 2 hours
            </MenuItem>
            <MenuItem
              key="5hours"
              onClick={() => snoozeTask(dayjs().add(5, 'hour'))}>
              For 5 hours
            </MenuItem>
            <MenuItem
              key="tomorrow"
              onClick={() => snoozeTask(dayjs().add(1, 'day'))}>
              Until Tomorrow
            </MenuItem>
            <MenuItem key="chooseDate" onClick={() => showModal(true)}>
              Choose a date
            </MenuItem>
          </Menu>
        )}>
        <SecondaryButton icon={<GreenClock />}>Snooze</SecondaryButton>
      </Dropdown>
    </Flex>
  );
};

export default SnoozeButton;
