import { useEffect, useState } from 'react';
import { Layout as AntdLayout, Drawer, LayoutProps, Menu, Spin } from 'antd';
import {
  HamburgerIcon,
  LogoTealSVG_V1,
  LogoTealSVG_V3,
  Logout,
  Settings,
} from '../../assets';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuItemType } from '../../types/menu.types';
import styled from '@emotion/styled';
import theme from '../../theme';
import { AvatarComponent, Flex, Image, Text } from '../../components';
import Profile from './Profile';
import { useAuth } from '../../providers/auth-context';
import { useGetMenuItems } from '../../utils/useGetMenuItems';
import { addDotsForLongText } from '../../utils/textUtil';
import { getPascalCaseUtil } from '../../utils/caseUtil';

const { Header } = AntdLayout;

const Logo = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
const MobileLogo = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

const TopMenu = styled(Header)<LayoutProps>`
  display: flex;
  align-items: center;
  height: 68px;
  padding: 0 32px;
  position: fixed;
  right: 0;
  left: 0;
  transition: width 0.2s;
  z-index: 1000;
  top: 0;
  background: ${theme.brandDarkTeal} !important;
  .ant-menu-dark.ant-menu-inline .ant-menu-item {
    height: 36px;
    border-radius: 24px;
    padding: 10px 88px;
  }
  .ant-menu-dark {
    border-radius: 24px;
    background: ${theme.brandDarkTeal}!important;
    padding: 10px 88px;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected {
    color: ${theme.brandDarkTeal};
    background-color: ${theme.brandCream};
    border-radius: 24px;
    height: 36px;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background: ${theme.brandCream};
    border-radius: 24px;
  }
  .ant-menu .ant-menu-title-content {
    font-family: SF Pro Display;
    font-size: 15px;
    font-weight: 400;
    line-height: 36px;
    border-radius: 24px;
  }
  .ant-menu .ant-menu-item a::before {
    border-radius: 24px;
  }
  .ant-menu-dark
    .ant-menu-item:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ):hover,
  .ant-menu-dark
    > .ant-menu
    .ant-menu-item:not(.ant-menu-item-selected):not(
      .ant-menu-submenu-selected
    ):hover,
  .ant-menu-dark
    .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
    > .ant-menu-submenu-title:hover,
  .ant-menu-dark
    > .ant-menu
    .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
    > .ant-menu-submenu-title:hover {
    color: ${theme.brandCream};
    border-radius: 24px;
  }
  .ant-menu-dark .ant-menu-item,
  .ant-menu-dark > .ant-menu .ant-menu-item,
  .ant-menu-dark .ant-menu-submenu-title,
  .ant-menu-dark > .ant-menu .ant-menu-submenu-title {
    color: ${theme.brandCream};
    border-radius: 24px;
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    border-radius: 24px;
  }

  .hamburgerIcon {
    display: none;
  }

  @media screen and (max-width: 840px) {
    justify-content: space-between;
    .responsiveMenu {
      display: none;
    }
    .hamburgerIcon {
      display: block;
    }
  }
`;

const MenuItem = styled(Menu.Item)`
  font-family: SF Pro Display;
  font-size: 15px;
  font-weight: 400;
  line-height: 36px;
  border-radius: 24px;
  margin: 0px !important;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    margin-top: 68px !important;
  }
  .ant-drawer-header {
    display: none;
  }
`;
const StyledName = styled(Text)`
  color: ${theme.white};

  @media screen and (max-width: 860px) {
    color: ${theme.brandDarkTeal};
  }
`;
const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LayoutHeader = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState<string>('');

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const navigate = useNavigate();

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const { currentUser, isLoading: authLoading, logout } = useAuth();

  const menuItems = useGetMenuItems(currentUser?.role.id);

  useEffect(() => {
    setActiveMenu(getCurrentActiveMenu(menuItems));
  }, [menuItems, location]);

  useEffect(() => {
    const currentItem = getCurrentActiveMenu(menuItems);
    if (currentItem !== activeMenu) {
      setActiveMenu(currentItem);
    }
  }, [activeMenu]);

  const getCurrentActiveMenu = (menu: MenuItemType[]) => {
    const currentPath = menu.find(
      (item: MenuItemType) =>
        item.path === `/${location.pathname.split('/')[1]}`,
    );
    return currentPath?.displayName || '';
  };

  return (
    <TopMenu>
      <Logo className="logo">
        <img src={LogoTealSVG_V1} width={167} height={24} />
      </Logo>
      <MobileLogo className="mobile_logo">
        <img src={LogoTealSVG_V3} height={24} />
      </MobileLogo>
      <Menu
        className="responsiveMenu"
        theme="dark"
        mode="horizontal"
        selectedKeys={[activeMenu]}
        style={{ flex: 1, minWidth: 0 }}
        onMouseEnter={(e) => e.stopPropagation()}
        onClick={(event) => setActiveMenu(event.key as string)}>
        {menuItems.map((item: MenuItemType) => {
          return (
            <MenuItem
              title={item.displayName}
              key={item.displayName}
              className={item.className}>
              <Link to={item.path}>
                <Flex display="flex" alignItems={'center'}>
                  <Image
                    mr="4px"
                    width={20}
                    height={20}
                    src={
                      item.path.includes(
                        `/${location.pathname.split('/')[1]}` + location.search,
                      )
                        ? require(`../../assets/icons/menuItems/${item.icon}`)
                        : require(
                            `../../assets/icons/menuItems/${item.iconUnselected}`,
                          )
                    }
                  />
                  <span> {item.displayName}</span>
                </Flex>
              </Link>
            </MenuItem>
          );
        })}
      </Menu>

      <div className="responsiveMenu">
        {authLoading ? <Spin spinning /> : <Profile />}
      </div>
      <div className="hamburgerIcon" onClick={showDrawer}>
        <img src={HamburgerIcon} />
      </div>
      <StyledDrawer
        title="test drawer"
        onClose={onClose}
        placement="right"
        open={openDrawer}
        width={300}
        rootStyle={{ height: '100vh' }}
        getContainer={false}>
        <DrawerContent>
          <Menu
            mode="vertical"
            selectedKeys={[activeMenu]}
            style={{ flex: 1, minWidth: 0 }}
            onMouseEnter={(e) => e.stopPropagation()}
            onClick={(event) => setActiveMenu(event.key as string)}>
            {menuItems.map((item: MenuItemType) => {
              return (
                <MenuItem
                  title={item.displayName}
                  key={item.displayName}
                  className={item.className}>
                  <Link to={item.path} onClick={onClose}>
                    <Image
                      mr="4px"
                      width={20}
                      height={20}
                      src={
                        item.path.includes(location.pathname + location.search)
                          ? require(
                              `../../assets/icons/menuItems/${item.iconUnselected}`,
                            )
                          : require(`../../assets/icons/menuItems/${item.icon}`)
                      }
                    />
                    <span> {item.displayName}</span>
                  </Link>
                </MenuItem>
              );
            })}
          </Menu>
          <div>
            {authLoading ? (
              <Spin spinning />
            ) : (
              <Flex display="flex" borderBottom={`1px solid ${theme.gray400}`}>
                <AvatarComponent
                  src={currentUser?.profilePicture}
                  size={40}
                  mr="10px"
                  shape="circle"
                  title={currentUser?.firstName + ' ' + currentUser?.lastName}
                />
                <Flex
                  p={'8px'}
                  pb={'4px'}
                  display="flex"
                  flexDirection="column">
                  <StyledName type="footnote-regular" color={theme.white}>
                    {currentUser
                      ? addDotsForLongText(
                          currentUser?.firstName + ' ' + currentUser?.lastName,
                          18,
                        )
                      : '-'}
                  </StyledName>
                  <Text type="caption1-regular" color={theme.brandTeal}>
                    {currentUser?.role.role
                      ? getPascalCaseUtil(currentUser?.role.role)
                      : '-'}
                  </Text>
                </Flex>
              </Flex>
            )}
          </div>
          <Flex padding={'12px 0px'}>
            <Flex
              p={'8px 0px'}
              onClick={() => {
                onClose();
                navigate('settings/profile');
              }}>
              <Flex display={'flex'}>
                <Settings />
                <Text type="subheading-regular" ml="8px" color={theme.black}>
                  Settings
                </Text>
              </Flex>
            </Flex>
            <Flex
              p={'8px 0px'}
              onClick={() => {
                logout();
                onClose();
                navigate('/login');
              }}>
              <Flex display={'flex'} mt={'8px'}>
                <Logout />
                <Text type="subheading-regular" ml="8px" color={theme.red}>
                  Logout
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </DrawerContent>
      </StyledDrawer>
    </TopMenu>
  );
};

export default LayoutHeader;
