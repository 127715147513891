import { FC, ReactElement } from 'react';
import { Flex } from '../../components/flex/Flex';
import styled from '@emotion/styled';
import theme from '../../theme';
import { AreaTypeEnum } from '../../types/equipmentsItems.types';
import { Image, Text } from '../../components';
import { BuildingImage, KitchenImage, SanitaryImage } from '../../assets';
import NameTag from '../../components/tags/NameTag';
import { capitalizeFirstLetter } from '../../utils/textUtil';

interface PropsI {
  areaType: AreaTypeEnum;
  areaName: string;
  isBookable: boolean;
}

interface StyledCardPropsI {
  isBookable: boolean;
}

const StyledCard = styled(Flex)<StyledCardPropsI>`
  height: 72px;
  width: 256px;
  display: flex;
  padding: 12px;
  border: 1px solid ${theme.gray300};
  border-radius: 8px;
  margin-right: 16px;
  margin-top: 16px;
  ${(props) => !props.isBookable && `background-color: ${theme.gray200}`}
`;

const AreaInfo = styled(Flex)`
  margin-left: 16px;
`;

const AreaImage = styled(Image)`
  height: 48px;
  width: 38px;
`;

const getAreaImage = (areaType: AreaTypeEnum): ReactElement => {
  switch (areaType) {
    case AreaTypeEnum.BUILDING:
      return <AreaImage src={BuildingImage} />;
    case AreaTypeEnum.KITCHEN:
      return <AreaImage src={KitchenImage} />;
    case AreaTypeEnum.SANITARY:
      return <AreaImage src={SanitaryImage} />;
  }
};

const AreaCard: FC<PropsI> = ({ areaType, areaName, isBookable }) => {
  return (
    <StyledCard isBookable={isBookable}>
      {getAreaImage(areaType)}
      <AreaInfo>
        <NameTag
          tagColor={isBookable ? theme.green : theme.red}
          textColor={theme.white}
          text={isBookable ? 'Available' : 'Not Available'}
          tagSize="tiny"
          isClickable={false}
        />
        <Text type="body-regular">{areaName}</Text>
      </AreaInfo>
    </StyledCard>
  );
};

export default AreaCard;
