import { FC, useEffect } from 'react';
import ModalComponent from '../../components/modals/ModalComponent';
import { Form, Select } from 'antd';
import { FormContainer, SelectComp } from '../../components';
import { InboxAreaI } from '../inbox/interfaces/inbox.interface';
import DateRangePicker from '../../components/datePicker/DateRangePicker';
import { ReserveAreaPostDataI } from './interfaces/venue.interface';
import dayjs, { Dayjs } from 'dayjs';

interface PropsI {
  onCancel: () => void;
  visible: boolean;
  areas: InboxAreaI[];
  reserveArea: (val: ReserveAreaPostDataI) => void;
  openDays: string[];
  startDate?: Dayjs;
  endDate?: Dayjs;
  selectedArea?: string;
  isLoading: boolean;
}

const AddNewReservationModal: FC<PropsI> = ({
  onCancel,
  visible,
  areas,
  reserveArea,
  openDays,
  startDate,
  endDate,
  selectedArea,
  isLoading,
}) => {
  const [form] = Form.useForm();

  const handleFinish = () => {
    const values: { area: string; date: Dayjs[] } = form.getFieldsValue();
    const reserveData = {
      areaId: values.area,
      startDate: values.date[0].format('YYYY-MM-DD'),
      endDate: values.date[1].format('YYYY-MM-DD'),
    };
    reserveArea(reserveData);
  };

  useEffect(() => {
    form.setFieldValue('area', areas[0].id);

    if (startDate) {
      form.setFieldValue('date', [startDate, endDate]);
    }
    if (selectedArea) {
      form.setFieldValue('area', selectedArea);
    }
  }, []);

  const disabledDate = (current: Dayjs) => {
    const isBeforeToday = current && current < dayjs().startOf('day');
    const isAllowedWeekday = openDays.includes(
      current.format('dddd').toUpperCase(),
    );
    return isBeforeToday || !isAllowedWeekday;
  };

  return (
    <ModalComponent
      onCancel={onCancel}
      onSubmit={() => form && form.submit()}
      header={'Add New Reservation'}
      hideCancel={false}
      loadingBtn={isLoading}
      disabled={isLoading}
      primaryBtnLabel="Reserve"
      visible={visible}>
      <FormContainer
        layout="vertical"
        form={form}
        validateTrigger="onBlur"
        onFinish={handleFinish}>
        <Form.Item
          label="Area *"
          name="area"
          rules={[{ required: true, message: 'Choose area' }]}>
          <SelectComp defaultValue={areas[0].id}>
            {areas?.map(
              (area) =>
                area.isBookable && (
                  <Select.Option key={area.id} value={area.id}>
                    {area.name}
                  </Select.Option>
                ),
            )}
          </SelectComp>
        </Form.Item>

        <Form.Item
          label="Date *"
          name="date"
          rules={[{ required: true, message: 'Choose the date' }]}>
          <DateRangePicker disabledDate={disabledDate} />
        </Form.Item>
      </FormContainer>
    </ModalComponent>
  );
};

export default AddNewReservationModal;
