import { FC, ReactNode } from 'react';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { CirclePlus, WhiteDropdown } from '../../assets';
import { PrimaryButton } from '../buttons/PrimaryButton';
import styled from '@emotion/styled';
import { SpaceProps, space } from 'styled-system';

const ResponsiveDropdown = styled(Dropdown)`
  @media (max-width: 768px) {
    width: 130px !important;
    padding: 0px !important;
  }
`;

type Props = SpaceProps & {
  label?: string;
  menu?: MenuProps;
  dropdownRender?: (menus: ReactNode) => ReactNode;
  showIcon?: boolean;
  customIcon?: ReactNode;
};

const DropdownButton = styled(PrimaryButton)<SpaceProps>`
  ${space}
`;

const ButtonDropdown: FC<Props> = ({
  label,
  menu,
  dropdownRender,
  showIcon = true,
  customIcon,
}) => (
  <ResponsiveDropdown menu={menu} dropdownRender={dropdownRender}>
    <DropdownButton
      size="middle"
      icon={showIcon ? <CirclePlus /> : customIcon ? customIcon : null}>
      <Space>
        {label ? label : ''}
        {showIcon && <img src={WhiteDropdown} />}
      </Space>
    </DropdownButton>
  </ResponsiveDropdown>
);

export default ButtonDropdown;
