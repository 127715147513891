import theme from '../../theme';
import { CleaningProductTypeEnum } from '../../types/cleaningProducts.types';
import { getPascalCaseUtil } from '../../utils/caseUtil';
import { Tag } from 'antd';
import styled from '@emotion/styled';
import { SpaceProps, space } from 'styled-system';
import { FC } from 'react';

type Props = SpaceProps & {
  tagColor: string;
  text: string;
  size?: 'small' | 'large';
};

export const CustomTag = styled(Tag)<{ size?: 'small' | 'large' }>`
  height: ${(props) => (props.size === 'small' ? '20px' : '28px')};
  padding: ${(props) => (props.size === 'small' ? '0px 4px' : '2px 12px')};
  text-align: left;
  font-family: SF Pro Display;
  font-size: ${(props) => (props.size === 'small' ? '11px' : '14px')};
  font-weight: ${(props) => (props.size === 'small' ? 590 : 400)};
  line-height: ${(props) => (props.size === 'small' ? '13px' : '20px')};
  border-radius: 8px;
  color: ${theme.brandDarkTeal}!important;
  cursor: pointer;
  ${space};
`;

export const CategoryTag: FC<Props> = ({ tagColor, text, size = 'large' }) => (
  <CustomTag color={tagColor} size={size}>
    {text}
  </CustomTag>
);

export const getCategoryTag = (
  type: CleaningProductTypeEnum,
  size?: 'small' | 'large',
) => {
  switch (type) {
    case CleaningProductTypeEnum.CHEMICAL:
      return (
        <CategoryTag
          size={size}
          tagColor={theme.lightCream}
          text={getPascalCaseUtil(type)}
        />
      );

    case CleaningProductTypeEnum.EQUIPMENT:
      return (
        <CategoryTag
          size={size}
          tagColor={theme.gray300}
          text={getPascalCaseUtil(type)}
        />
      );

    default:
      null;
  }
};
