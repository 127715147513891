import { FC } from 'react';
import { Flex, FormContainer, Input, Text } from '../../components';
import styled from '@emotion/styled';
import theme from '../../theme';
import dayjs from 'dayjs';
import { Col, Form, Row } from 'antd';
import CustomTextArea from '../../components/TextArea/CustomTextArea';
import ImagePreview from '../../components/image/ImagePreview';
import { RequestI } from './interfaces/inbox.interface';
import { InboxType } from '../enums/Inbox.enum';

const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const StatusBar = styled(Flex)`
  display: flex;
  align-items: center;
  background-color: ${theme.gray200};
  justify-content: space-between;
  height: 42px;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid ${theme.gray400};
`;

const Body = styled(Flex)`
  height: 100%;
  margin: 16px 24px;
`;

const DisabledInput = styled(Input)`
  &:disabled {
    color: ${theme.black};
    background-color: ${theme.white} !important;
  }
`;

const DisabledTextArea = styled(CustomTextArea)`
  color: red !important;
  &.ant-input-outlined.ant-input-disabled,
  &.ant-input-outlined[disabled],
  &:disabled {
    color: ${theme.black} !important;
    background-color: ${theme.white} !important;
  }

  &.ant-input-outlined.ant-input-disabled,
  &.ant-input-outlined[disabled] {
    color: ${theme.black} !important;
    background-color: ${theme.white} !important;
  }
`;

interface PropsI {
  request: RequestI;
}
const InboxItemView: FC<PropsI> = ({ request }) => {
  const [form] = Form.useForm();

  if (request) {
    form.setFieldsValue({
      item: request.item?.name
        ? request.item.name?.en
        : request.item?.defaultItemId.name?.en,
      venue: request.venue?.name,
      area: request.area?.name,
      description: request.description,
      title: request.title,
    });
  }

  form.getFieldsValue();

  return (
    <Container>
      <StatusBar>
        <Flex height="42px" display="flex" alignItems="center">
          <Text type="footnote-regular">Created by: </Text>
          <Text type="footnote-bold" ml="3px">
            {request?.createdBy.firstName} {request?.createdBy.lastName}
          </Text>
        </Flex>
        <Flex display="flex">
          <Text type="footnote-regular">
            {dayjs(request?.lastUpdated).format('ddd YYYY-MM-DD')}
          </Text>
          <Text type="footnote-bold" ml="3px">
            {dayjs(request?.lastUpdated).format('HH:mm')}
          </Text>
        </Flex>
      </StatusBar>
      <Body>
        <FormContainer form={form} layout="vertical" validateTrigger="onBlur">
          {request?.item &&
            (request?.item.name || request?.item?.defaultItemId.name) && (
              <Row gutter={[16, 16]}>
                <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={<Text type="subheading-bold">Item</Text>}
                    name="item">
                    <DisabledInput disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
            )}

          <Row gutter={[16, 16]}>
            {request?.venue?.name && (
              <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={<Text type="subheading-bold">Venue</Text>}
                  name="venue">
                  <DisabledInput disabled={true} />
                </Form.Item>
              </Col>
            )}
            {request?.area?.name && (
              <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  label={<Text type="subheading-bold">Area</Text>}
                  name="area">
                  <DisabledInput disabled={true} />
                </Form.Item>
              </Col>
            )}
          </Row>

          {request?.type === InboxType.TODO && request.title && (
            <Row gutter={[16, 16]}>
              <Col sm={24} md={24} lg={24} xl={24} xxl={12}>
                <Form.Item
                  label={<Text type="subheading-bold">Title</Text>}
                  name="title">
                  <DisabledInput value={request?.title} />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col sm={24} md={24} lg={24} xl={24} xxl={12}>
              <Form.Item
                label={
                  <Text type="subheading-bold">
                    {request?.type === InboxType.REQUEST
                      ? 'Issue Description'
                      : 'Description'}
                  </Text>
                }
                name="description">
                <DisabledTextArea value={request?.description} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col sm={24} md={24} lg={24} xl={24} xxl={12}>
              {request?.images && request?.images.length > 0 && (
                <Form.Item
                  label={<Text type="subheading-bold">Images</Text>}
                  name="images">
                  <Flex display="flex" flexWrap="wrap">
                    {request?.images?.map((image, index) => (
                      <ImagePreview
                        width="140px"
                        height="140px"
                        key={index}
                        m="0px 16px 16px 0px"
                        src={image}
                      />
                    ))}
                  </Flex>
                </Form.Item>
              )}
            </Col>
          </Row>
        </FormContainer>
      </Body>
    </Container>
  );
};

export default InboxItemView;
