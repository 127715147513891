import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import styled from '@emotion/styled';
import theme from '../../theme';

interface TextAreaProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

const StyledTextArea = styled(TextArea)`
  border: 0.5px solid ${theme.gray400} !important;
  box-sizing: border-box;
  padding-left: 16px;
  border-radius: 8px;
  font-family: SF Pro Display;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  box-shadow: none !important;

  &:focus {
    border-color: ${theme.gray400} !important;
  }

  &:hover {
    border-color: ${theme.gray600} !important;
    box-sizing: border-box;
    border-radius: 8px;
  }
`;

const CustomTextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder,
  disabled,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <StyledTextArea
      value={value}
      onChange={handleInputChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default CustomTextArea;
