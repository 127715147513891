import { useMutation, useQuery } from 'react-query';
import { http } from './_apiService';
import { ApiError } from './_types';
import { AxiosError } from 'axios';
import { OrgVenuesI, VenueFormI } from '../types/venue.types';
import {
  CancelReservationI,
  ReservationsAreaI,
  ReserveAreaPostDataI,
} from '../containers/venues/interfaces/venue.interface';
import { Message } from '../components/message/Message';

export const useGetVenues = () =>
  useQuery<OrgVenuesI[], AxiosError>('getVenues', async () => {
    const response = await http.get('/users/owner-venues');
    return response.data;
  });

export const useUpdateBusinessHours = () =>
  useMutation<string, ApiError, VenueFormI>(async (data: VenueFormI) => {
    const { id, ...businessHourData } = data;
    const response = await http.put(
      `/venue/business-hours/${id}`,
      businessHourData,
    );
    return response.data;
  });

export const useReserveArea = () =>
  useMutation<string, ApiError, ReserveAreaPostDataI>(
    async (data: ReserveAreaPostDataI) => {
      const response = await http.post(`/venue/reserve`, data);
      return response.data;
    },
    {
      onError: (error) => {
        Message.error(error.msg);
      },
    },
  );

export const useGetReservations = () =>
  useQuery<ReservationsAreaI[], AxiosError>('getReservations', async () => {
    const response = await http.get('/venue/reservations');
    return response.data;
  });

export const useCancelReservation = () =>
  useMutation<string, ApiError, CancelReservationI>(
    async (data: CancelReservationI) => {
      const response = await http.delete(
        `/venue/reservation/${data.areaId}/${data.date}`,
      );
      return response.data;
    },
  );
