import dayjs from 'dayjs';
import { DateFrequencyEnum } from '../types/report.types';

export const getLastNDays = (Ndays: DateFrequencyEnum): string[] => {
  switch (Ndays) {
    case DateFrequencyEnum.LAST30DAYS: {
      const last30Days = Array.from({ length: 30 }, (_, i) =>
        dayjs()
          .subtract(29 - i, 'day')
          .format('M-D'),
      );
      return last30Days;
    }
    case DateFrequencyEnum.LAST14DAYS: {
      const last14days = Array.from({ length: 14 }, (_, i) =>
        dayjs()
          .subtract(13 - i, 'day')
          .format('M-D'),
      );
      return last14days;
    }
    case DateFrequencyEnum.LAST7DAYS: {
      const last7days = Array.from({ length: 7 }, (_, i) =>
        dayjs()
          .subtract(6 - i, 'day')
          .format('M-D'),
      );
      return last7days;
    }
  }
};

export const convertToADateDataObject = (days: string[]) => {
  return Object.assign(
    {},
    ...days.map((day, index) => ({ [`date_${index + 1}`]: day })),
  );
};

export const getNoOfCols = (schedule: DateFrequencyEnum) => {
  let noOfCols = 30;
  switch (schedule) {
    case DateFrequencyEnum.LAST30DAYS:
      noOfCols = 30;
      break;
    case DateFrequencyEnum.LAST14DAYS:
      noOfCols = 14;
      break;
    case DateFrequencyEnum.LAST7DAYS:
      noOfCols = 7;
      break;
  }
  return noOfCols;
};
