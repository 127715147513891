import React, { CSSProperties } from 'react';
import {
  color,
  ColorProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import styled from '@emotion/styled';

export type FontTypes =
  | 'tiny'
  | 'body-bold'
  | 'callout-bold'
  | 'subheading-bold'
  | 'footnote-bold'
  | 'caption1-bold'
  | 'caption2-bold'
  | 'body-regular'
  | 'callout-regular'
  | 'subheading-regular'
  | 'footnote-regular'
  | 'caption1-regular'
  | 'caption2-regular'
  | 'regular';

type Props = React.HTMLAttributes<HTMLDivElement> &
  SpaceProps &
  ColorProps &
  TypographyProps & {
    type: FontTypes;
    children?: string | React.ReactNode;
    id?: string;
    display?: CSSProperties['display'];
    onClick?: () => void;
    cursor?: CSSProperties['cursor'];
  };

const getStyleFontType = (type: FontTypes) => {
  switch (type) {
    case 'body-bold':
      return `
      font-size: 17px;
      font-weight: 590;
      line-height: 22px;
      `;
    case 'callout-bold':
      return `
      font-size: 16px;
      font-weight: 590;
      line-height: 21px;
      `;
    case 'subheading-bold':
      return `
      font-size: 15px;
      font-weight: 590;
      line-height: 20px;
      `;
    case 'footnote-bold':
      return `
      font-size: 13px;
      font-weight: 590;
      line-height: 18px;
      `;
    case 'caption1-bold':
      return `
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      `;
    case 'caption2-bold':
      return `
      font-size: 11px;
      font-weight: 590;
      line-height: 13px;
      `;
    case 'body-regular':
      return `
      font-size: 17px;
      font-weight: 400;
      line-height: 22px;
      `;
    case 'callout-regular':
      return `
      font-size: 16px;
      font-weight: 400;
      ine-height: 21px;
      `;
    case 'subheading-regular':
      return `
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      `;
    case 'footnote-regular':
      return `
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      `;
    case 'caption1-regular':
      return `
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      `;
    case 'caption2-regular':
      return `
      font-size: 11px;
      font-weight: 400;
      line-height: 13px;
      `;
    case 'regular':
      return `
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      `;
    case 'tiny':
      return `
      font-size: 9px;
      font-weight: 400;
      line-height: 10px;
      `;
  }
};
export const Text = styled.p<Props>`
  margin-bottom: 0px;
  font-family: SF Pro Display;
  letter-spacing: -0.4000000059604645px;
  cursor: ${(props) => props.cursor && props.cursor};
  ${(props) => getStyleFontType(props.type)}
  ${typography}
  ${space}
  ${color}
`;
