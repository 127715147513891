import { Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Flex } from '../../components';
import styled from '@emotion/styled';
import CustomCollapse from './VenueCollapse';
import {
  useCancelReservation,
  useGetReservations,
  useGetVenues,
  useReserveArea,
} from '../../api/venueHooks';
import AreaCard from './AreaCard';
import { InboxAreaI } from '../inbox/interfaces/inbox.interface';
import ReservationCalendar from './ReservationCalendar';
import theme from '../../theme';
import AddNewReservationModal from './AddNewReservationModal';
import { Dayjs } from 'dayjs';
import { ReserveAreaPostDataI } from './interfaces/venue.interface';

const MainContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
`;

const AreaCardSection = styled(Flex)`
  display: flex;
  flex-wrap: wrap;
`;

const TopSection = styled(Flex)``;
const CalendarSection = styled(Flex)`
  margin-top: 24px;
  background-color: ${theme.white};
  border-radius: 8px;
  border: 1px solid ${theme.gray300};
  padding-bottom: 8px;
  height: 65vh;
`;
const Footer = styled(Flex)``;

const Venues: FC = () => {
  const { data: venues, isLoading: venuesLoading } = useGetVenues();
  const [areas, setAreas] = useState<InboxAreaI[]>([]);
  const [bookableAreas, setBookableAreas] = useState<InboxAreaI[]>([]);
  const [visibleReserveModal, setVisibleReserveModal] =
    useState<boolean>(false);

  const [startReservationDate, setStartReservationDate] = useState<Dayjs>();
  const [endReservationDate, setEndReservationDate] = useState<Dayjs>();
  const [selectedAreaInCalendar, setSelectedAreaInCalendar] =
    useState<string>();

  const {
    mutate: reserveArea,
    isLoading: isLoadingReserveArea,
    isSuccess: isSuccessReserveArea,
  } = useReserveArea();
  const {
    data: reservations,
    isLoading: isLoadingReservations,
    refetch: refetchReservations,
  } = useGetReservations();
  const {
    mutate: cancelReservation,
    isLoading: isLoadingCancelReservation,
    isSuccess: isSuccessCancelReservation,
  } = useCancelReservation();

  useEffect(() => {
    if (venues) {
      const areasArr = venues[0].areas;
      setAreas(areasArr);
    }
  }, [venues]);

  useEffect(() => {
    if (isSuccessReserveArea || isSuccessCancelReservation) {
      setVisibleReserveModal(false);
      refetchReservations();
    }
  }, [isSuccessReserveArea, isSuccessCancelReservation]);

  useEffect(() => {
    const filteredAreas = areas.filter((area) => area.isBookable);
    setBookableAreas(filteredAreas);
  }, [areas]);

  return (
    <Spin
      spinning={
        venuesLoading ||
        isLoadingReserveArea ||
        isLoadingReservations ||
        isLoadingCancelReservation
      }>
      <MainContainer>
        <TopSection>
          {venues && (
            <CustomCollapse venue={venues[0]}>
              <AreaCardSection>
                {areas.map((area) => (
                  <AreaCard
                    areaName={area.name}
                    areaType={area.type}
                    key={area.id}
                    isBookable={area.isBookable}
                  />
                ))}
              </AreaCardSection>
            </CustomCollapse>
          )}
        </TopSection>
        <CalendarSection>
          <ReservationCalendar
            reservations={reservations}
            disableReserveBtn={bookableAreas.length === 0}
            openReserveModal={() => setVisibleReserveModal(true)}
            cancelReservation={cancelReservation}
            setStartDate={(val: Dayjs) => setStartReservationDate(val)}
            setEndDate={(val: Dayjs) => setEndReservationDate(val)}
            openDays={venues ? venues[0].openDays : []}
            setSelectedAreaInCalendar={(val: string) =>
              setSelectedAreaInCalendar(val)
            }
          />
        </CalendarSection>
      </MainContainer>
      <Footer></Footer>
      {visibleReserveModal && venues && (
        <AddNewReservationModal
          visible={visibleReserveModal}
          onCancel={() => {
            setVisibleReserveModal(false);
            setStartReservationDate(undefined);
            setEndReservationDate(undefined);
          }}
          areas={bookableAreas}
          reserveArea={(val: ReserveAreaPostDataI) => {
            reserveArea(val);
            setStartReservationDate(undefined);
            setEndReservationDate(undefined);
          }}
          openDays={venues[0].openDays}
          startDate={startReservationDate}
          endDate={endReservationDate}
          selectedArea={selectedAreaInCalendar}
          isLoading={isLoadingReserveArea}
        />
      )}
    </Spin>
  );
};

export default Venues;
